<template>
  <div class="modal fade" :id="modalId" tabindex="-1" ref="modalDom"
    :data-bs-backdrop="outsideClose ? 'true' : 'static'" :data-bs-keyboard="outsideClose ? 'true' : 'false'">
    <!-- modal-xl -->
    <div class="modal-dialog" :class="['modal-' + modalSize]">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="col-12 modal-title text-center">
            <slot name="modal-header" />
          </h5>
        </div>
        <div class="modal-body">
          <slot name="modal-body" />
        </div>
        <!-- end of modal-body -->
        <div class="modal-footer" :class="{ 'justify-content-center': btnCenter }">
          <slot name="modal-footer" />
          <button v-if="closeBtn" type="button" class="btn btn-primary" @click="cancelEdit">
            關閉
          </button>
        </div>
        <!-- end of modal-footer -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { Modal } from "bootstrap"; // Collapse move to component

const props = defineProps({
  modalId: {
    type: String,
    default: "genericModal",
  },
  outsideClose: {
    type: Boolean,
    default: () => false
  },
  closeBtn: {
    type: Boolean,
    default: () => false
  },
  btnCenter: {
    type: Boolean,
    default: () => false
  },
  size: {
    type: String,
    default: 'lg'
  }
});



const emit = defineEmits(['onModalOpen', 'onModalClose', 'onModalMounted'])

const onModalHidden = () => {
  emit('onModalOpen');
};

const onModalShown = () => {
  emit('onModalClose');
};

const modalSize = computed(() => {
  const availableSize = ['sm', 'lg', 'xl'];
  if (availableSize.includes(props.size)) {
    return props.size
  } else {
    return 'lg'
  }
})

const modalDom = ref(null); // 實際的Modal DOM
let modalInstance;
onMounted(() => {
  modalDom.value.addEventListener("hidden.bs.modal", onModalHidden);
  modalDom.value.addEventListener("shown.bs.modal", onModalShown);
  modalInstance = Modal.getOrCreateInstance(modalDom.value);
  console.log('modal mounted ', modalInstance);
  emit('onModalMounted', modalInstance);
});

onBeforeUnmount(() => {
  modalDom.value.removeEventListener("hidden.bs.modal", onModalHidden);
  modalDom.value.removeEventListener("shown.bs.modal", onModalShown);
});

const cancelEdit = () => {
  console.log('modalInstance123', modalInstance);
  modalInstance.hide();
};

const test = () => {
  console.log('modal test has been called');
}

defineExpose({ test });
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>

<style lang="scss">
/* @import "@/scss/custom"; */
</style>
<!-- $primary: #ccc;
$danger: red;
 
@import "bootstrap"; -->
<!-- bootstrap 5 customize https://blog.pureday.life/archives/1589 -->
