import { useI18n, createI18n } from 'vue-i18n'


import zh from "./zh-TW.json";
import en from "./en-US.json";
import ja from "./ja-JP.json";


// 本地新增
zh.yup = { min: '{0} 必須大於 {1}。', no_label: '本欄位', reason: '原因', status: '狀態' };
ja.yup = { min: '{0} は {1} より大きくなければなりません', no_label: 'このフィールド', reason: '理由', status: '状態' }; en.yup = { min: '{0} must greater than {1}.' };

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol'
    }
  },
  'zh-TW': {
    currency: {
      style: 'currency',
      currency: 'TWD',
      currencyDisplay: 'symbol'
    }
  }
}

const datetimeFormats = {
  'en-US': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric'
    }
  },
  'ja-JP': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true, second: 'numeric'
    }
  },
  'zh-TW': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric'
    }
  },
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") ?? "zh-TW",
  fallbackLocale: "zh-TW",
  globalInjection: true,
  warnHtmlMessage: false,
  messages: {
    "zh-TW": zh,
    "en-US": en,
    "ja-JP": ja
  },
  numberFormats,
  datetimeFormats,
  fallbackWarn: false,
  missingWarn: false
});


export default i18n

import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from 'dayjs/plugin/localizedFormat'

import "dayjs/locale/en";
import "dayjs/locale/zh-tw";
import "dayjs/locale/ja";

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

const mapping = { "en-US": "en", "zh-TW": "zh-tw", "ja-JP": "ja" };

const getDatetimeString = (time, format = "LLLL") => {
  const { locale } = useI18n();
  return dayjs(time).locale(mapping[locale.value]).format(format)
}

export { dayjs, mapping, getDatetimeString };