import { createStore } from 'vuex'
// import device from '@/store/modules/fake'
import device from '@/store/modules/device'
import fake from '@/store/modules/fake'
import ubus from '@/store/modules/ubus'
import mqtt from '@/store/modules/mqtt'
import auth from '@/store/modules/auth'
import ui from '@/store/modules/ui'
import location from '@/store/modules/location'
import locations from '@/store/modules/locations'
import notification from '@/store/modules/notification'
import administration from '@/store/modules/administration'
import fhir from '@/store/modules/fhir'
import config from '@/store/modules/config'
// import mqtt from '@/store/modules/mqtt'

export default createStore({
  // strict: true,
  modules: {
    device,
    ubus,
    auth,
    location,
    locations,
    config,
    notification,
    administration,
    fake,
    fhir,
    mqtt,
    ui,
    // mqtt,
  }

})
