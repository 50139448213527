import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Mqtt.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/new',
  //   name: 'HomeNew',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/HomeNew.vue')
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue')
  },
  // {
  //   path: '/test2',
  //   name: 'Test2',
  //   component: () => import(/* webpackChunkName: "test" */ '@/views/field_array_test.vue')
  // },
  {
    path: '/mqtt_utils',
    name: 'MqttUtils',
    component: () => import(/* webpackChunkName: "mqtt_utils" */ '@/views/MqttUtils.vue')
  },
  {
    path: '/fhir',
    name: 'Fhir',
    component: () => import(/* webpackChunkName: "test" */ '@/views/Fhir.vue')
  },
  {
    path: '/device',
    name: 'Device',
    component: () => import(/* webpackChunkName: "device" */ '@/views/Device.vue')
  },
  {
    path: '/practitioner',
    name: 'Practitioner',
    component: () => import(/* webpackChunkName: "attendant" */ '@/views/Practitioner.vue')
  },
  {
    path: '/patient',
    name: 'Patient',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Patient.vue')
  },
  {
    path: '/emergency',
    name: 'Emergency',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Emergency.vue')
  },
  {
    path: '/mqtt',
    name: 'MQTT',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Mqtt.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Form.vue')
  },
  {
    path: '/home',
    name: 'HomeOld',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Home.vue')
  },
  {
    path: '/config',
    name: 'Config',
    component: () => import(/* webpackChunkName: "patient" */ '@/views/Config.vue'),
    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/test/:id',
    name: 'TestParameters',
    component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue'),
    beforeEnter(to) {
      const id = parseInt(to.params.id);
      if (id !== 1234) {
        return {
          name: "not-found",
          params: { pathMatch: to.path.substring(1).split('/') }, // pathMatch: 陣列
          query: to.query,
          hash: to.hash,
        }
      }
    }
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    component: () => import(/* webpackChunkName: "sandbox" */ '@/views/Sandbox.vue'),
  },
  {
    path: '/location',
    name: 'Location',
    component: () => import(/* webpackChunkName: "location" */ '@/views/Location.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/drag',
    name: 'Drag',
    component: () => import(/* webpackChunkName: "Drag" */ '@/views/Drag.vue')
  },
  {
    path: '/log',
    name: 'LogQuery',
    component: () => import(/* webpackChunkName: "Drag" */ '@/views/LogQuery.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// https://book.vue.tw/CH4/4-2-route-settings.html

// 檢查頁面是否需要登入 ... 
router.beforeEach((to, from) => {
  let loggedin = store.getters['auth/isLoggedIn'];
  let requiresAuth = to.meta?.requiresAuth
  if (requiresAuth && !loggedin) {
    store.state.auth.auth_fail_route_name = to.name;
    store.commit('notification/addAlert', {
      i18n: {
        title: 'login-please',
        message: 'alert-page-permission-error',
      },
      route: 'Login',
      category: 'auth-fail'
    });
    return { name: 'Login' }
  }
  // 當進入任何不需要認證的頁面時(Login除外)，清除auth_fail_route_name
  if (!requiresAuth && to.path !== '/login') {
    store.state.auth.auth_fail_route_name = undefined;
    store.commit('notification/deleteAlertByCategory', 'auth-fail');
  }
  // 進入 /login 頁面的時，記錄先前的頁面為何，在登入後可以轉回先前頁面。
  if (to.path === '/login' && from.path !== '/login') {
    store.state.auth.before_login_name = from.name;
  }
});

export default router
