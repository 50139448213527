<template>
  <!-- 最上層 選單 -->
  <header-bar class="header-block" />
  <!-- 第二層 選單 -->
  <menu-bar :list="menu" class="menu-block" />
  <div class="alert-block">
    <alert-block />
  </div>
  <router-view />
  <!-- 左下角 按鍵 -->
  <float-btn />
  <!-- 左側 選單 -->
  <side-bar />
  <toast-area />
  <!-- 確認Modal -->
  <confirm-modal />
</template>

<script>
// import { useAxios } from '@vueuse/integrations'

import HeaderBar from "@/components/HeaderBar.vue";
import MenuBar from "@/components/MenuBar.vue";
import SideBar from "@/components/offcanvas/SideBar.vue";
import FloatBtn from "@/components/ui/FloatBtn.vue";
import AlertBlock from "@/components/ui/AlertBlock.vue";
import ToastArea from "@/components/ui/ToastArea.vue";
import ConfirmModal from '@/components/modal/ConfirmModal';

import { defineComponent, onMounted } from "vue"; // eslint-disable-line
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { UbusRequest } from "@/helper/ubus";

export default defineComponent({
  app: "App",
  components: {
    HeaderBar,
    MenuBar,
    SideBar,
    FloatBtn,
    AlertBlock,
    ToastArea,
    ConfirmModal
  },

  async setup() {
    // move to Main.vue

    onMounted(() => {
      // window.addEventListener('beforeunload', (e) => {
      //   sessionStorage.setItem('reloaded', 'yes');
      // });
    });

    const { mergeLocaleMessage } = useI18n();

    const store = useStore();
    await store.dispatch("auth/checkSessionExpire");
    await store.dispatch("config/initialize");
    await store.dispatch('config/getConfigFromUbus', 'dashboard')
    await store.dispatch('config/getConfigFromUbus', 'test')

    await store.dispatch('config/getOneConfigFromUbus', 'mqtt_server')

    // 載入 buildings -> state.buildings
    await store.dispatch('locations/loadDataFromUbus');
    // 載入 status_codes
    await store.dispatch('config/loadStatusCodesFromUbus');
    // 載入 reason_codes
    await store.dispatch('config/loadReasonCodesFromUbus');



    const i18n_list = await UbusRequest({ namespace: 'i18n_list', procedure: 'query', anonymous: true });

    if (typeof (i18n_list.list) !== 'undefined') {
      i18n_list.list.forEach(row => {
        mergeLocaleMessage(row.locale, { [row.code]: row.text });
      })
    }

    function timeoutPromise(interval, hasError) {
      return new Promise((resolve, reject) => {
        setTimeout(function () {
          //   resolve("done")
          if (!hasError) {
            resolve();
          } else {
            reject("資料庫連線失敗");
          }
        }, interval);
      });
    }

    let hasError = false;

    while (store.state.config.load_progress < 100) {
      await timeoutPromise(10, hasError);
      store.commit('config/load_progress_add');
    }

    let menu = [
      { route: "/", text: "資訊看版", translateCode: "dashboard" },
      { route: "/about", text: "關於大心", translateCode: "about-us" },
      // { route: "/login", text: "登入" },
    ];



    return { menu };
  },
});
</script>

<style>
.header-block {
  position: sticky;
  top: 0px;
  z-index: 1039;
}

.menu-block {
  position: sticky;
  top: 50px;
  z-index: 1039;
}

.alert-block {
  position: sticky;
  top: 90px;
  z-index: 1039;
  background-color: #ffffff;
}
</style>