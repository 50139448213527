import { integer } from "@vee-validate/rules";
import dateFormat from "dateformat";
import { v4 as uuid } from "uuid";


export default {
  namespaced: true,
  state() {
    const manufacturerList = [
      { name: '大心生物科技', code: 'daxin' }
    ]
    const deviceTypeList = [
      { code: 'smart-toilet', name: '智慧排遺設備', manufacturers: ['daxin'] },
      { code: 'urine-bag', name: '智慧尿袋設備', manufacturers: ['daxin'] }
    ]
    const deviceIdentity = [
      {
        id: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        manufacturer: 'daxin',
        type: 'smart-toilet',
      },
      {
        id: 'a9b156cf-c24d-4a15-9777-47547ac92559',
        manufacturer: 'daxin',
        type: 'urine-bag',
      },
    ]
    // 定義：設備有的屬性，即用來描述設備的欄位
    const deviceProperties = [
      {
        id: '6839faa5-badb-407a-8b46-a95fd35cf011',
        genericId: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        properties: [
          { id: '1c74964d-ce61-4dc3-8374-087614784122', code: 'current_water_temperature', name: '目前水溫', type: 'Float', unit: 'water_temperature', sort: 1 },
          { id: '2bf82bdb-c0fe-4dfb-be8b-81590266df4f', code: 'target_water_temperature', name: '設定水溫', type: 'Float', unit: 'water_temperature', sort: 2 },
          { id: '931fd93a-aabe-4195-9ca0-d7bf17d1434b', code: 'stool_count', name: '大便次數', type: 'Integer', unit: '', sort: 3 },
          { id: 'b6c7fe20-f93b-4699-9f71-52993ef09447', code: 'urine_count', name: '小便次數', type: 'Integer', unit: '', sort: 4 },
          { id: '0f8598e1-1d3c-457f-85b7-534a70da90d0', code: 'power_on', name: '設備開機', type: 'Boolean', unit: '', sort: 5 },
          { id: 'e7ab1893-5762-4222-b9ab-0a4a8bbab7c9', code: 'stand_by', name: '設備待機', type: 'Boolean', unit: '', sort: 6 },
          { id: 'b07059b7-5aba-4105-b531-9f72434fcabb', code: 'operating', name: '是否運作中', description: '0:待機 1:運作中', type: 'Integer', unit: '', sort: 7 },
          { id: '4afe16e5-7692-40ea-b235-1eb0084ebd84', code: 'op_type', name: '運作類型', description: '0:待機 1:大便 2:小便', type: 'Integer', unit: '', sort: 8 },
        ]
      },
      {
        id: '4ff0adb9-6c1c-4b69-9f3e-2d249765f8a0',
        genericId: 'a9b156cf-c24d-4a15-9777-47547ac92559',
        properties: [
          { id: '33742b62-c4c0-418f-9f5a-49b1c014e134', code: 'powered_on', name: '是否開機', type: 'Boolean', unit: '', sort: 1 },
          { id: '0c07d3d9-ed78-4161-9b72-e2e994cf1041', code: 'battery_status', name: '電池電量', type: 'Float', unit: '', sort: 1 },
          { id: '68b9ebc6-25e6-44e3-8fb1-28189206eddd', code: 'motor_op_time', name: '馬達作動時間', type: 'Float', unit: '', sort: 1 },
          { id: '4d9af420-a42b-4fc0-a701-50403c340c56', code: 'avg_urine_time', name: '平均小便時間', type: 'Float', unit: '', sort: 1 },
          { id: '5f14edc6-5035-49ff-aa7f-9918c7014b4d', code: 'daily_urine_count', name: '今日小便次數', type: 'Integer', unit: '', sort: 1 },
          { id: 'a811ebbc-d444-4060-baf1-cabc9d27b38e', code: 'urine_delay_count', name: '小便計數延時', type: 'Integer', unit: '', sort: 1 },
        ]
      }
    ]

    // 事件 ... 用於記錄發生了什麼事？ 例如：
    // information: 設備開、關機
    // vital-signs: 大便發生、小便發生
    // warning: 不影響使用的事件，如水溫偏低 (但仍可正常運作)
    // alert: 告警，導致設備無法使用的事件 
    // 通用屬性
    // 發生時間
    // 結束時間
    // 
    const deviceEvents = [
      {
        id: '7171b969-2e81-4361-82a0-422aa444b3b6',
        genericId: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        eventDefinition: {
          alerts: [ // start_t , end_t , acknology_t
            { id: 'a0af4e20-252d-44ab-92f8-085bb4853df0', code: 'waste-bucket-misplaced', name: '污桶位移', description: '污桶未正確定位，請確認是否放置良好，將污桶抽出後重新安置，若持續告警請連絡技術人員進行障礙排除', sort: 1 },
            { id: '59cce536-0867-46e8-af1f-c6f5b01338a6', code: 'waste-bucket-full', name: '污桶已滿', description: '', sort: 2 },
            { id: 'ac63ee3e-95e8-43db-9846-f43e056d69e2', code: 'water-shortage', name: '水箱缺水', description: '', sort: 3 },
            { id: '6dfef476-12ed-466a-88e6-61e0f33d9ed3', code: 'hardware-error', name: '空燒警告', description: '', sort: 4 },
            { id: 'a80137ff-9c1a-4700-99af-96a5692d80cc', code: 'water-overheated', name: '水溫過高', description: '', sort: 5 },
          ],
          operation: [
            {
              id: '75f90a17-2783-49eb-83e2-d28018d7baa0', code: 'stool-procedure', name: '大便行程', measurements: ['stool_weight'], count: 'stool_count',
              trigger: {
                start: { property: "operating", type: "rising_edge", condition: { op_type: 1 } }, end: { property: "operating", type: "falling_edge" },
                records: []
              }
            },
            { id: '4539b91c-aa1d-4a8a-8640-4240c7a66db9', code: 'stool-procedure', name: '小便行程', measurements: ['urine_volume'], count: 'urine_count' },
          ]
        }
      }
    ]

    const apis = [
      {
        id: '1ea5033d-93c7-4216-b95f-90492c7deaa0',
        genericId: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
        apis: [
          { id: '5bd6b309-71cd-454d-b415-a5190bb2b88f', }
        ]
      }
    ]
    // 2023-01-05 設備所使用到的度量衡 需考慮到該屬性所適用的類別 例如 當單位的類別為溫度時，設定設備屬性時選擇布林值確出現單位不是很奇怪嗎？
    // 
    const deviceMeasurement = [
      { id: '5ba94b30-ba02-4397-9774-126c1b917bf7', code: 'stool_weight', name: '大便重量', measure: 'mass', unit: ['kg', 'g', 'mg'], type: 'Float' },
      { id: '7ad70f30-c7d0-4bb6-8853-d0f11c95f95a', code: 'urine_volume', name: '尿量', measure: 'volume', unit: ['l', 'ml'], type: 'Float' },
      { id: 'a3256300-2a38-4358-ba0c-2c4ff4091996', code: 'water_temperature', name: '水溫', measure: 'temperature', unit: ['C'], type: 'Float' },
    ]

    const alertCodes = {
      'smart-toilet': [
        { code: 'waste-bucket-misplaced', name: '污桶位移', description: '' },
        { code: 'waste-bucket-full', name: '污桶已滿', description: '' },
        { code: 'water-shortage', name: '水箱缺水', description: '' },
        { code: 'hardware-error', name: '空燒警告', description: '' },
        { code: 'water-overheated', name: '水溫過高', description: '' },
      ]
    }
    const list = [
      {
        deviceId: "cc5c7fb9-9d39-467a-8ab9-726d3bac078b",
        deviceName: "DX-001",
        type: "urine-bag",
        patient: 'b4783286-d9f2-4350-82c2-75a980bce46c',
        owner: '',
        location: '',
        serialNumber: '1234567',
        assetNumber: 'abcdefg',
        manufacturer: 'daxin',
        deviceStatus: 'assigned', // registered: 已登記 available: 可使用 unavailable: 不可使用
        reasonCode: '', // 例如： maintainace: 維護 RMA: 送修 malfunction: 故障 retired: 退疫
        // 自定義項目
        expirationDate: '', // 自動填入
        registeredDate: new Date(Date.parse('2024-01-29')), // 啟用時間, //登記時間
        activeDate: new Date(Date.parse('2024-01-29')), // 啟用時間
        lastStatusModifyDate: new Date(Date.parse('2022-07-11 12:34:56')), // 啟用時間
        alertList: ['water-shortage', 'waste-bucket-misplaced'], //
        // visiable: true,
      },
      {
        deviceId: "979c50e6-bba5-4205-9c73-e576309e9863",
        deviceName: "DX-002",
        type: "urine-bag",
        patient: '',
        owner: '',
        location: '',
        serialNumber: '',
        assetNumber: '',
        manufacturer: 'daxin',
        expirationDate: '', // 自動填入
        deviceStatus: 'unavailable', // registered: 已登記 available: 可使用 unavailable: 不可使用
        reasonCode: 'not-acceptance', // 例如： maintainace: 維護 RMA: 送修 malfunction: 故障 retired: 退疫
        // 自定義項目
        registeredDate: new Date(Date.parse('2024-01-29')), //登記時間 自動填入
        activeDate: new Date(Date.parse('2024-01-29')), // 啟用時間
        lastStatusModifyDate: undefined,
        alertList: [],
        // visiable: true,
      },
      {
        deviceId: "b1743347-7b08-462b-a837-a2ba6c4c56ff",
        deviceName: "DX-003",
        type: "urine-bag",
        patient: '',
        owner: '',
        location: '',
        serialNumber: '',
        assetNumber: '',
        manufacturer: 'daxin',
        expirationDate: '', // 自動填入
        deviceStatus: 'available', // registered: 已登記 available: 可使用 unavailable: 不可使用
        reasonCode: '', // 例如： maintainace: 維護 RMA: 送修 malfunction: 故障 retired: 退疫
        // 自定義項目
        registeredDate: new Date(Date.parse('2024-01-29')), //登記時間
        activeDate: new Date(Date.parse('2024-01-29')), // 啟用時間
        lastStatusModifyDate: undefined,
        alertList: [],
        // visiable: true,
      }

    ];

    // 2023-01-05 設備log所儲存的資料 設備儲存的資料需要由Property這邊來，另外還要自定義需要儲存的時間、序號等規則
    // 是否在設定設備資料時，就選擇是否需要LOG，及LOG機制，例如上緣觸發 下緣觸發

    // 2024-05-21 將設備資訊由 store/ubus 移到 store/device 
    const device_log = {}; // 排遺記錄存區
    const device_status = []; // 設備清單 
    const device_statistic = []; // 設備狀態 
    const urine_bag_statistic = [];
    //[
    //   {
    //           "device_time": 1723458888,
    //           "status_code": "device_off",
    //           "name": "ub001",
    //           "statistic_id": 6,
    //           "device_id": 6,
    //           "mac": "aabbccddeeffgg",
    //           "battery": "97.03",
    //           "status_name": "設備停止運作"
    //   }
    //]


    const sortBy = 'device_name';
    const device_filter = '';
    const show_offline = false;

    const type_filter = 'all';
    const available_types = ['urine_bag', 'diaper_free'];

    return {
      list,
      // reasonCodes, statusCodes
      alertCodes,
      deviceTypeList,
      manufacturerList,
      deviceIdentity,
      deviceProperties,
      deviceEvents,
      deviceMeasurement,
      // 2024-05-21 新增
      device_log,
      device_status,
      device_statistic,
      urine_bag_statistic,
      sortBy, // 設備排序方式 'device_name' or 'update-time'
      device_filter, // 篩選器
      show_offline, // 是否顯示離線設備
      online_status: [], // 用於儲存上線狀態
      type_filter, // 用來篩選設備 'diaper_free' & 'urine_bag'
      available_types, // 用於顯示所有可用的設備類型，暫時寫死
    }
  },
  mutations: { // $store.commit('increment',1) // 之後要把新增/修改項目至 state 放這裡 ... 
    setTargetRoom(state, roomId) { // eslint-disable-line
      state.targetRoom = parseInt(roomId)
    },
    modifyProperty(state, { genericId, properties }) {
      // 0. 處理排序...
      properties = Array.from(properties).map((item, idx) => {
        item.sort = idx + 1;
        return item
      })
      // 1. 查詢是否已有存在
      let target = state.deviceProperties.find(item => item.genericId === genericId);
      if (target) {
        target.properties = properties;
      } else {
        state.deviceProperties.push({ id: uuid(), genericId, properties })
      }
    },
    modifyAlert(state, { genericId, alerts }) {
      alerts = Array.from(alerts).map((item, idx) => {

        item.sort = idx + 1;
        return item
      })
      // 1. 查詢是否已有存在
      let target = state.deviceEvents.find(item => item.genericId === genericId);
      if (target) {
        target.eventDefinition.alerts = alerts;
      } else {
        state.deviceProperties.push({ id: uuid(), genericId, eventDefinition: { alerts } })
      }
    },
    // 2024-05-21 新增
    // mqtt 右上角切換排序
    toggleSortBy: (state) => {
      if (state.sortBy === 'device_name') {
        state.sortBy = 'update_time'
      } else {
        state.sortBy = 'device_name'
      }
    },
    // mqtt 右上角切換是否顯示離線設備 ... 
    toggleShowOffline: (state) => { state.show_offline = !state.show_offline },
    // mqtt 右上 篩選設備名稱
    updateDeviceFilter: (state, data) => {
      state.device_filter = data;
    },
    // mqtt 右上角 篩選設備類型 (智慧尿袋 & 智慧尿布)
    updateTypeFilter: (state, type) => {
      if (state.available_types.includes(type)) {
        console.log('updateTypeFilter set to', type)
        state.type_filter = type;
      } else {
        console.log('updateTypeFilter set to ', 'all')
        state.type_filter = 'all';
      }
    }
  },
  actions: { // asynchronized request $store.dispatch('addRandomNumber')
    // 之後會把資料放到db撈取，目前先不處理
    async addRandomNumber(context) { // context is everything inside our store
      const url = 'https://www.random.org/integers/?num=1&min=1&max=100&col=1&base=10&format=plain&rnd=new'
      const response = await fetch(url)
      const number = await response.text()
      // context.commit('increment', parseInt(number))
    },
    // 待刪
    // loadDataFromDb(interval) {
    //   interval = parseInt(interval) || 1 // simulator loading time
    //   interval *= 1000
    //   return new Promise((resolve) => {
    //     setTimeout(function () {
    //       resolve('data finish loading');
    //     }, interval);
    //   });
    // },
    addNewManufacturer: (context, data) => {
      context.state.manufacturerList.push(data);
      return true;
    },
    addNewDeviceType: (context, { code, name, manufacturer }) => {
      // const { code, name, manufacturer } = data;
      // let target = []
      const target = context.state.deviceTypeList.filter(item => item.code === code && item.name === name);
      if (target.length === 0) {
        context.state.deviceTypeList.push({
          code, name,
          manufacturers: [manufacturer],
        })
      } else {
        if (target[0].manufacturers.includes(manufacturer) === false) {
          target[0].manufacturers.push(manufacturer);
        }
      }
      // 2022-08-10 新增檢查若 deviceIdentity 沒有的話就新增一筆...
      if (!context.state.deviceIdentity.some(item => (item.type === code && item.manufacturer === manufacturer))) {
        context.state.deviceIdentity.push({
          id: uuid(),
          type: code,
          manufacturer
        })
      }

      return true;
    },
    addDevice: (ctx, data) => {
      // 前端驗證已完成，後端驗證之後再做...
      data.deviceName = 'DX-' + data.deviceName;
      data.alertList = [];
      data.registeredDate = new Date();
      ctx.state.list.push(data)
      return true
    },

    modifyDevice: (ctx, data) => {
      return new Promise(resolve => {
        let target = ctx.state.list.find(device => device.deviceId === data.deviceId);
        if (target) {
          for (let property in target) {
            if (data[property] !== undefined && target[property] !== data[property]) {
              target[property] = data[property]
            }
          }
        }
        resolve(true);
      })
    },

    assignDevice: (ctx, data) => {
      const device = ctx.state.list.find(device => device.deviceId === data.deviceId);

      if (device && device.deviceStatus === 'available') {
        device.patient = data.patientId;
        device.deviceStatus = 'assigned';
        device.reasonCode = '';
        device.lastStatusModifyDate = new Date();
        return true;
      }
      return false;
    },

    retainDevice: (ctx, data) => {
      const device = ctx.state.list.find(device => device.deviceId === data.deviceId);
      if (device && device.patient === data.patientId) {
        device.patient = '';
        device.deviceStatus = 'available';
        device.reasonCode = '';
        device.lastStatusModifyDate = new Date();
        return true;
      }

      return false;
    },
    modifyDeviceMeasurement: (ctx, data) => {
      return new Promise((resolve, reject) => {
        // 找到目標
        let target = ctx.state.deviceMeasurement.find(item => item.code === data.code)
        if (!target) {
          reject(`目標${data.code}不存在`);
        }

        target.measure = data.measure;
        target.unit = data.unit;
        target.type = data.type;

        resolve(true)
      })
    },
    addDeviceMeasurement: (ctx, data) => {
      return new Promise((resolve, reject) => {
        if (!data.id) {
          data.id = uuid();
        }
        // check if duplicate...
        const duplicateCheck = {};
        if (ctx.state.deviceMeasurement.find(item => item.name === data.name)) {
          duplicateCheck.name = `${data.name}已存在`;
        }
        if (ctx.state.deviceMeasurement.find(item => item.code === data.code)) {
          duplicateCheck.code = `${data.code}已存在`;
        }

        if (Object.keys(duplicateCheck).length > 0) {
          reject(duplicateCheck);
        }

        ctx.state.deviceMeasurement.push(data);

        if (ctx.state.deviceMeasurement.find(item => item.id === data.id)) {
          resolve('true')
        } else {
          resolve('false')
        }
        // resolve(true);
      })
    },
    modifyProperty: (ctx, [genericId, properties]) => {
      //
      return new Promise((resolve, reject) => {
        ctx.commit('modifyProperty', { genericId, properties })
        if (ctx.state.deviceProperties.some(item => item.genericId === genericId)) {
          resolve(true);
        } else {
          reject('false');
        }
      })
    },
    modifyAlert: (ctx, [genericId, alerts]) => {
      //
      return new Promise((resolve, reject) => {
        ctx.commit('modifyAlert', { genericId, alerts })
        if (ctx.state.deviceProperties.some(item => item.genericId === genericId)) {
          resolve(true);
        } else {
          reject('false');
        }
      })
    },

    // 用於接收mqtt訊息 daxin/{mac_address}/status 訊息時，更新 state 的設備狀態使用
    updateDeviceStatistic: ({ state }, data) => {
      console.log("updateDeviceStatistic has been triggered", data);
      let id;
      state.device_statistic.forEach((device, idx) => {
        if (device.mac === data.mac) {
          id = idx;
        }
      })
      console.log("id:", id)
      if (id !== 'undefined') {
        state.device_statistic[id] = data;
      }
    },

  },
  getters: {
    // roomEixsts(state) {
    //   return state.targetRoom * 2
    // },
    peopertyMapping: (state, getters, rootState, rootGetters) => id => {
      let result = {
        ...state.list.find(device => {
          return device.deviceId === id
        })
      }
      // mapping property
      if (result.deviceStatus && rootState.config.reasonCodes[result.deviceStatus] !== undefined) {
        result.reasonCodeName = rootState.config.reasonCodes[result.deviceStatus].find(list => list.code === result.reasonCode)?.name || '無';
      } else {
        result.reasonCodeName = '無'
      }
      // alertList property
      if (result.alertList && state.alertCodes[result.type] !== undefined) {
        result.alertInfo = result.alertList.reduce((acc, alert) => {
          let name = state.alertCodes[result.type].find(item => item.code === alert).name

          if (name) { acc.push(name) }
          return acc
        }, [])
      } else {
        result.alertInfo = [];
      }
      if (result.patient !== '') {
        let patient = rootState.administration.patients.find(patient => patient.id === result.patient)
        if (patient) {
          result.patientName = patient.familyName + patient.givenName;
        } else {
          result.patientName = '不明';
        }
      } else {
        result.patientName = '無人使用中';
      }

      result.deviceStatusName = rootState.config.statusCodes.find(list => list.code === result.deviceStatus)?.name || '無';
      result.typeName = state.deviceTypeList.find(list => list.code === result.type)?.name || '無';
      result.manufacturerName = state.manufacturerList.find(list => list.code === result.manufacturer)?.name || '無';
      result.activeDateString = dateFormat(result.activeDate, 'yyyy-mm-dd');
      result.registeredDateString = dateFormat(result.registeredDate, 'yyyy-mm-dd');
      return result;
    },
    filterdList: (state, getters) => (filter, option = {}) => {
      let resultAry = [];
      if (filter === undefined) {
        resultAry = state.list
      } else {
        // return state.list.filter(device => {
        //   return Object.keys(filter).some(property => {
        //     return device[property] === filter[property]
        //   })
        // })
        resultAry = state.list.reduce((filtered, device) => {
          let isMatched = Object.keys(filter).every(property => {
            if (property === 'deviceName') { // 設備名稱用模糊比對
              return filter[property] === '' || device[property].includes(filter[property]);
            } else { // 其他用精確比對
              return filter[property] === '' || device[property] === filter[property]
            }
          })
          if (isMatched) {
            filtered.push(device);
          }
          return filtered
        }, [])
      }

      let tmp = resultAry.map(device => getters.peopertyMapping(device.deviceId))
      // debugger;
      return tmp
    },
    // 待刪
    // queryDevice: (state) => filter => {
    //   return state.list;
    // },

    getReasonCodes: (state, getters, rootState) => code => {
      return rootState.config.reasonCodes[code]
    },
    getStatusCodes: (state, getters, rootState) => rootState.config.statusCodes,
    getManufactureList: state => state.manufacturerList,
    getDeviceTypeList: state => manufacturer => {
      if (manufacturer === undefined) {
        return state.deviceTypeList
      } else {
        return state.deviceTypeList.reduce((list, deviceType) => {
          if (deviceType.manufacturers.includes(manufacturer)) {
            // delete (deviceType.manufacturers);
            list.push(deviceType);
          }
          return list
        }, [])
      }
    },
    getDeviceNextNo: state => {
      let tmp = [...state.list]
      tmp.sort(function (a, b) {
        return b.deviceName.localeCompare(a.deviceName)
      })
      if (tmp.length === 0) {
        return '001';
      } else {
        let max = parseInt(tmp[0].deviceName.replace('DX-', ''));
        max++;
        return ('0000' + max).slice(-3)
      }
    },
    // const deviceIdentity = [
    //   {
    //     id: '9eea9f2d-dd70-4010-9e14-43176e5d8bdf',
    //     manufacturer: 'daxin',
    //     type: 'smart-toilet',
    //   },
    // ]
    getDeviceIdentity: (state) => ({ manufacturer, type }) => {
      let result = state.deviceIdentity.filter(item => (item.manufacturer === manufacturer && (type === '' || item.type === type)));
      result = result.map(item => {
        item.name = state.deviceTypeList.find(deviceType => deviceType.code === item.type)?.name;
        return item;
      })
      return result;
    },

    getDeviceProperties: (state) => (id) => {
      return state.deviceProperties.find(item => item.genericId === id);
    },

    getDeviceEvents: (state) => (id) => {
      return state.deviceEvents.find(item => item.genericId === id);
    },

    // 處理 state 內的資料 處理好後回傳以顯示於頁面
    getDeviceStatistic: state => {
      // 這樣 沒記錯是為了讓他不會影響到實際的 state 資料 ... 
      let tmp = [];

      if (state.type_filter === 'all' || state.type_filter === 'diaper_free') {
        tmp = [...tmp, ...state.device_statistic]
      }

      if (state.type_filter === 'all' || state.type_filter === 'urine_bag') {
        tmp = [...tmp, ...state.urine_bag_statistic]
      }

      let status_mapping = { 1: "上線", 2: "逾時", 3: "離線" }
      let status_code_mapping = { 1: "online", 2: "timeout", 3: "offline" }

      state.online_status.forEach(device => {
        let target = tmp.find(d => d.mac === device.mac)
        if (target) {
          target.online_status = status_mapping[device.status];
          target.online_code = status_code_mapping[device.status];
        }
      })
      tmp.forEach(device => {
        if (device.online_status === undefined) {
          device.online_status = '未知';
          device.online_code = 'unknown';
        }
      })
      if (state.show_offline === false) {
        tmp = tmp.filter(device => device.online_code === 'online')
      }
      if (state.device_filter.length > 0) {
        tmp = tmp.filter(device => device.name.includes(state.device_filter))
      }
      // 依設備名稱排序
      if (state.sortBy === 'device_name') {
        tmp = tmp.sort((a, b) => {
          // if (state.sort_by.device_time === 'desc') {
          return a.name > b.name ? 1 : -1
          // } else {
          //   return a.device_time < b.device_time ? 1 : -1
          // }
        })
      } else { // 依更新時間排序
        tmp = tmp.sort((a, b) => {
          // if (state.sort_by.device_time === 'desc') {
          return a.device_time < b.device_time ? 1 : -1
          // } else {
          // return a.device_time > b.device_time ? 1 : -1
          // }
        })
      }
      // console.log('tmp2', tmp);
      return tmp
    },

  }
}