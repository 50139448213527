<template>
  <div class="menu">
    <nav class="navbar navbar-expand-lg navbar navbar-dark bg-primary">
      <div class="container-fluid justify-content-between">
        <router-link v-for="item in list" :key="item.route" class="navbar-nav text-decoration-none text-light"
          :to="item.route">
          {{ $t(item.translateCode) }}
        </router-link>
        <router-link v-show="username === ''" class="navbar-nav text-decoration-none text-light" to="/login">{{
          $t("login") }}</router-link>
        <div role="button" v-show="username !== ''" @click="logout" class="text-light text-decoration-none">
          {{ $t("logout") }}({{ username }})
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { defineComponent, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MenuBar",
  props: { list: { type: Object, required: true } },
  async setup() {
    onMounted(() => {
    });

    const store = useStore();
    const router = useRouter();
    const username = computed(() => {
      return store.getters["auth/getLoginData"];
    });

    const logout = () => {
      store.dispatch("auth/ubusLogout");
      router.push("/");
    };

    watch(username, (newVal, oldVal) => {
      let message;
      if (newVal === "") {
        message = `帳號<font color="red">${oldVal}</font>已登出`;
        store.dispatch('auth/clearCheckSessionInterval');
      } else {
        message = `帳號<font color="red">${newVal}</font>已登入`;
        store.dispatch('auth/setCheckSessionInterval');
      }

      const cfg = { closeAfter: 5, route: "global" };
      const param = { title: "帳號狀態異動", message, cfg };

      store.commit("notification/addToast", param);
    });

    const keep_login = computed(() => store.state.auth.keep_login);

    // const login_session_timtout = computed(() => store.getters['auth/getRemainingLoginTimt'])

    const logout_at_close = computed(() => store.state.auth.logout_at_close);

    const test = () => {
      store.state.auth.logout_at_close = false;
    }
    return {
      test,
      logout_at_close,
      username,
      logout,
      // login_session_timtout,
      keep_login
    };
  },
});
</script>

<style></style>