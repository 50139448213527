import store from '@/store'

function ParamBuilder(params) { // eslint-disable-line
    let ubus_rpc_session = '00000000000000000000000000000000';
    if (params.anonymous !== true && store.state.auth.ubus_rpc_session) {
        ubus_rpc_session = store.state.auth.ubus_rpc_session
    }
    // const ubus_rpc_session = store.state.auth.ubus_rpc_session || '00000000000000000000000000000000'
    const namespace = params.namespace
    const procedure = params.procedure
    const method = params.method || 'call'
    const data = params.data || {}
    let template = { jsonrpc: '2.0', id: 1, method, params: [ubus_rpc_session, namespace, procedure, data] }
    const resultString = JSON.stringify(template)
    return resultString
}


// 資料格式: 
/*
    { namespace, procedure, data }
*/
// 只需要提供 data -> 實際要傳送到 api 的參數
export async function UbusRequest(argument) {
    let result = {}
    let dataToSend = ParamBuilder(argument)
    let api_cfg = store.state.config.api_server
    let api_url = api_cfg.protocol + '://' + api_cfg.host + api_cfg.prefix
    if (argument.host) {
        api_url = argument.host
    }
    const postData = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: dataToSend
    }
    // console.log('api_url:', api_url);
    const response = await fetch(api_url, postData)
    const data = await response.json()
    if (data.error) {
        if (data.error.code === -32002) { // access denied
            console.log('-32002 error:', postData)
            if (postData.ubus_rpc_session !== '00000000000000000000000000000000') {
                store.dispatch('auth/ubusLogout');
                store.commit('notification/addToast', { title: 'API存取錯誤', message: '可能為登入已失效，或是權限設定錯誤。', cfg: { closeAfter: 10 } })
            }
        }
        if (data.error.code === -32000) {
            console.log('-32000 error:', postData)
            store.commit('notification/addToast', { title: 'API不存在', message: '請檢查API程式是否正確執行。', cfg: { closeAfter: 10 } })
        }
    } else if (data.result) {
        if (data.result[0] == 0) {
            result = data.result[1] || {}
            result.error = false
        } else {
            result.error = true
        }
    }
    return result
}

// export { UbusRequest }