import { v4 as uuid } from 'uuid'; // eslint-disable-line
import moment from 'moment';
import device from './device';
import MD5 from 'crypto-js/md5';
import { nextDay } from 'date-fns';

export default {
  namespaced: true,
  state() {
    const patients = [
      {
        id: 'b4783286-d9f2-4350-82c2-75a980bce46c',
        givenName: '大天',
        familyName: '陳',
        gender: 'male',
        // birthDate: 'Thu, 16 Jun 1955 09:07:04 GMT+0800 (台北標準時間)',
        birthDate: '1955-06-16',
        mainContactId: '9fa9319e-3878-4a94-bb14-b111d7d86a18',
        contactPhone: '0900456789',
        // residentSince: 'Fri Jun 05 1998 17:07:00 GMT+0800 (台北標準時間)',
        residentSince: '1992-06-18',
        deviceId: ['cc5c7fb9-9d39-467a-8ab9-726d3bac078b'],
        languages: ['mandarin', 'taiwanese'],
        bedId: 'defdcd81-0310-4d72-8bdc-6a731ca8a0e2',
        attendantId: '422c1c54-8d80-40e7-8594-68ec1d0c92d1',
        location: '',
        tags: [
          {
            name: "防跌",
            start_t: moment('20220714 0800', 'YYYYMMDD hhmm').toDate().toString(),
            end_t: moment('20220715 0000', 'YYYYMMDD hhmm').toDate().toString()
          },
          { name: "臥床", start_t: "", end_t: "" },
        ],
      },
      {
        id: '8afccac3-7a8e-4ea4-b7fb-9374218678bf',
        familyName: '王',
        givenName: '美美',
        gender: 'female',
        birthDate: moment('19380823', 'YYYYMMDD').toDate(),
        mainContactId: '34ffb397-165c-4dcf-815d-81b219c26f32',
        contactPhone: '0900456789',
        // residentSince: 'Thu, 05 Jul 2018 00:00:00 GMT+0800 (台北標準時間)',
        residentSince: moment('20180714', 'YYYYMMDD').toDate(),
        deviceId: [],
        languages: ['mandarin', 'taiwanese'],
        bedId: '',
        attendantId: '422c1c54-8d80-40e7-8594-68ec1d0c92d1',
        location: '',
        tags: [
          { name: "防跌", start_t: moment('20180715 0800', 'YYYYMMDD hhmm').toDate().toString(), end_t: moment('20180716 0800', 'YYYYMMDD hhmm').toDate().toString() },
        ],
      },
    ];



    const contacts = [
      {
        id: '9fa9319e-3878-4a94-bb14-b111d7d86a18',
        patientId: 'b4783286-d9f2-4350-82c2-75a980bce46c',
        name: '陳小天',
        telecom: '0900123123',
        relationship: '兒子',
        gender: '男'
      },
      {
        id: '34ffb397-165c-4dcf-815d-81b219c26f32',
        patientId: '8afccac3-7a8e-4ea4-b7fb-9374218678bf',
        name: '王小美',
        telecom: '0922554555',
        relationship: '孫女',
        gender: '女'
      },
    ]

    const practitioners = [
      {
        id: '422c1c54-8d80-40e7-8594-68ec1d0c92d1',
        name: '陳阿秋',
        birthDate: '16 Jun 1955',
        employeeSince: '16 Jun 2012',
        gender: 'female',
        telecom: ['0912121212'],
        photo: '',
        qualifications: [ // 相關證書 如 照顧服務員職類技術士證
          // {id: '', name: '', peroid: {start:'', end: ''}, issuer: ''}
          'a0001'
        ],
        languages: ['mandarin', 'taiwanese'],
        position: 'attendant',
        username: 'att000001',
        img_base64: ''
      },
      {
        id: '43181d40-94d5-4177-9d50-75d850341ef8',
        name: '張妹滿',
        birthDate: '16 Jun 1955',
        gender: 'female',
        telecom: ['0988123456'],
        photo: '',
        qualifications: [ // 相關證書 如 照顧服務員職類技術士證
          // {id: '', name: '', peroid: {start:'', end: ''}, issuer: ''}
        ],
        languages: ['mandarin', 'hakka'],
        position: 'attendant',
        username: 'att000002',
        img_base64: ''
      },
      {
        id: '12fc19eb-19cb-43cb-abbf-78e842bf4baf',
        name: '蔡招弟',
        birthDate: '16 Jun 1955',
        gender: 'female',
        telecom: ['0988688188'],
        photo: '',
        languages: ['mandarin', 'cantonese'],
        qualifications: [ // 相關證書 如 照顧服務員職類技術士證
          // {id: '', name: '', peroid: {start:'', end: ''}, issuer: ''}
        ],
        position: 'attendant',
        username: 'att000003',
        img_base64: ''

      },

    ]

    const languageCodes = [
      { code: 'mandarin', name: '國語' },
      { code: 'taiwanese', name: '台語' },
      { code: 'hakka', name: '客家話' },
      { code: 'english', name: '英文' },
      { code: 'japanese', name: '日文' },
      { code: 'cantonese', name: '廣東話' },
    ]

    const genderCodes = [
      { code: 'male', name: '男' },
      { code: 'female', name: '女' },
      { code: 'unknown', name: '不明' },
    ]

    const positionCodes = [
      { code: 'attendant', name: '照服員' },
      { code: 'nurse', name: '護理人員' },
      { code: 'techsupport', name: '資訊人員' },
      { code: 'headnurse', name: '護理長' },
    ]

    const certificateList = [
      { code: 'a0001', name: '照顧服務員單一級技術士技能證照', issuer: 'wdasec', ca: ['wdasec', 'shield'] },
    ]
    // Certificate Authority 發證單位
    const caList = [
      { code: 'wdasec', name: '發展署技能檢定中心' },
      { code: 'shield', name: '國土戰略防禦攻擊與後勤保障局' }
    ]

    const certificates = [
      {
        id: '30d3e789-098f-4f6d-bc17-6e569bcd2f17',
        practitionerId: '422c1c54-8d80-40e7-8594-68ec1d0c92d1',
        certificateCode: 'a0001',
        caCode: 'wdasec',
        issueDate: 'Thu Jun 18 2020 14:30:00 GMT+0800 (台北標準時間)',
        expireDate: 'Wed Jun 19 2024 14:30:00 GMT+0800 (台北標準時間)',
      }
    ]

    // 警急通告機制
    
    const contactGroups = [
      { id: '49378864-b45e-4d55-98f5-2d5ed5ee20ad', name: '休克', description: '當發生有需要急救時通報群組' }
    ]

    const groupTeams = [
      { id: 1, groupId: '49378864-b45e-4d55-98f5-2d5ed5ee20ad', code: 'group-a', name: 'A組'},
      { id: 1, groupId: '49378864-b45e-4d55-98f5-2d5ed5ee20ad', code: 'group-b', name: 'B組'},
    ]
    // 大類
    const contactCategories = [
      { id: 1, code: 'first-aid', name: '緊急救難' },
      { id: 2, code: 'security-issue', name: '保安事件' },
      { id: 3, code: 'fire', name: '火警事件' },
    ]

    const specializeTags = [
      {id: 1, code: 'CPR', name: '心肺復甦術'},
      {id: 2, code: 'AED', name: '自動體外心臟去顫器'},
      {id: 3, code: 'BLS', name: '基本救命術'},
    ]

    return {
      positionCodes, certificateList, caList,
      patients, contacts, practitioners,
      genderCodes, languageCodes, certificates

    }
  },
  mutations: {
    addContact: (state, data) => {
      state.contacts.push({ ...data });
    },
    //其實新增修改應該要寫在同一個
    modifyPatient: (state, data) => {
      if (typeof data.patientName === 'string' && data.patientName.length >= 2) {
        data.familyName = data.patientName.substring(0, 1);
        data.givenName = data.patientName.substring(1);
        delete data.patientName;
      }
      let target = state.patients.find(patient => patient.id === data.id);
      if (typeof target === 'object') {
        for (let property in target) {

          if (data[property] && data[property] !== undefined) {
            if (property === 'deviceId') {
              target[property] = [data[property]];
              //  device 要設定 deviceStatus: 'assigned'
              //  及填入 patientId
            } else {
              target[property] = data[property];
            }
          }
        }
      }
    },
    addPatient: (state, data) => {
      if (typeof data.patientName === 'string' && data.patientName.length >= 2) {
        data.familyName = data.patientName.substring(0, 1);
        data.givenName = data.patientName.substring(1);
        delete data.patientName;
      }
      data.deviceId = [data.deviceId]
      data.bedId = data.bedId || undefined;
      data.language = data.language || [];
      data.telecom = [data.telecom];
      state.patients.push({ ...data });
    },
    addPractitioner: (state, data) => {
      if (data.password) {
        data.password = MD5(data.password).toString();
        delete (data.confirmPassword);
      }
      if (typeof data.telecom === 'string') {
        data.telecom = [data.telecom];
      }
      state.practitioners.push({ ...data });
    },
    deleteContactById: (state, id) => {

      state.contacts = state.contacts.filter(contact => contact.patientId !== id)
    }
  },
  actions: {
    updatePractitionerQulification: (ctx, id) => {
      // 1. check if practitioner exists
      let targetPractitioner = ctx.state.practitioners.find(item => item.id === id)
      if (targetPractitioner.id === id) {
        // 2. get the certificate
        let certs = ctx.getters.getPractitionerCertificate({ practitionerId: id });
        if (certs.length > 0) {
          // 3.add certificateCode into practitioners ...
          let codes = certs.map(cert => cert.certificateCode);
          targetPractitioner.qualifications = codes;
        }
      }
    },
    deleteContactById: (ctx, id) => {

      ctx.commit('deleteContactById', id);
      return ctx.state.contacts.some(contact => contact.patientId === id) === false
    },
    addContact: async (ctx, data) => {
      data.id = data.id || uuid()
      return new Promise((resolve, reject) => {
        let id = data.id;
        ctx.commit('addContact', data);
        if (ctx.state.contacts.some(contact => contact.id === id)) {
          resolve(true);
        } else {
          reject(false);
        }
      })
    },
    addPatient: (ctx, data) => {
      let id = data.id
      return new Promise((resolve, reject) => {
        ctx.commit('addPatient', data)
        if (ctx.state.patients.some(patient => patient.id === id)) {
          resolve(true);
        } else {
          reject('病人新增失敗');
        }
      })
    },
    modifyPractitioner: (ctx, data) => {
      if (data.confirmPassword !== undefined) {
        delete (data.confirmPassword)
      }
      return new Promise((resolve, reject) => {
        try {
          let target = ctx.state.practitioners.find(item => item.id === data.id);
          if (target !== undefined) {
            Object.keys(data).forEach((property) => {
              if (property === 'password') {
                if (target[property] !== undefined && target[property].includes(data[property])) {
                  return
                } else {
                  data[property] = MD5(data[property]).toString();
                }
              }

              if (property === 'telecom') {
                if (typeof data[property] === 'string') {
                  data[property] = [data[property]];
                }
              }
              target[property] = data[property];

            })
          }
          resolve(true);
        } catch (e) {
          reject(e.message);
        }
      })
    },
    addPractitioner: (ctx, data) => {
      let id = data.id
      return new Promise((resolve, reject) => {
        ctx.commit('addPractitioner', data)
        if (ctx.state.practitioners.some(patient => patient.id === id)) {
          resolve(true);
        } else {
          reject('病人新增失敗');
        }
      })
    },
    modifyPatient: (ctx, data) => {
      return new Promise((resolve, reject) => {

        // 查詢是否該住民 
        let patient = ctx.state.patients.find(patient => patient.id === data.id);
        // 若查不到該住民
        if (!patient) {
          reject('patient_not_found');
        }

        // 將原有的 deviceId 及 bedId 先讀出來
        let deviceIdOrig = [...patient?.deviceId]; // 取得會是個陣列 ... 
        let deviceIdNew = data.deviceId; // 取得會是個陣列 ... 
        let bedIdOrig = patient.bedId;
        let bedIdNew = data.bedId;
        // 呼叫 mutation 進行資料寫入
        ctx.commit('modifyPatient', data)

        // 檢查是否寫入完成 ... 

        if (Object.keys(data).every(property => {
          let result = true;
          if (data[property]) {
            if (property === 'deviceId') {
              result = patient[property].includes(data[property]);
            } else {
              result = patient[property] === data[property]
            }
          }
          return result;
        })) {
          // 移除不見的 devicdId
          let difference = deviceIdOrig.filter(device => !device.includes(patient.deviceId));
          difference.forEach(deviceId => ctx.dispatch('device/retainDevice', { patientId: patient.id, deviceId }, { root: true }))
          if (data.deviceId && !deviceIdOrig.includes(data.deviceId)) {
            ctx.dispatch('device/assignDevice', { patientId: patient.id, deviceId: data.deviceId }, { root: true })
          }
          // bedId
          if (bedIdOrig !== bedIdNew) {
            // remove patient from old bed
            ctx.dispatch('location/delPatientFromBed', { patientId: patient.id, bedId: bedIdOrig }, { root: true })
            // add patient to new bed
            ctx.dispatch('location/setPatientToBed', { patientId: patient.id, bedId: bedIdNew }, { root: true })
          }

          resolve(true);
        } else {
          reject('病人修改失敗');
        }

      })

    },
    addPractitionerCertificate: (ctx, data) => { // TODO: 還要加入更新的檢查 ... 
      return new Promise((resolve, reject) => {
        ctx.state.certificates.push(data);
        ctx.dispatch('updatePractitionerQulification', data.practitionerId);
        resolve(true)
      })
    }
  },
  getters: {
    peopertyMapping: (state, getters, rootState, rootGetters) => id => {
      let result = {
        ...state.patients.find(patient => {
          return patient.id === id
        })
      }
      result.name = result.familyName + result.givenName;
      result.genderString = state.genderCodes.find(gender => gender.code === result.gender)?.name || '未設定';
      result.languages = result.languages?.map(language => {
        return state.languageCodes.find(item => item.code === language)?.name || undefined
      })
      // result.attendantId = getters.getFilterData('practitioner', { position: 'attendant' });
      result.contacts = state.contacts.reduce((acc, item) => {
        if (item.patientId === result.id) {
          let is_primary = false;
          if (item.id === result.mainContactId) {
            is_primary = true;
          }
          acc.push({ ...item, is_primary })
        }
        return acc
      }, [])

      result.birthDate = moment(result.birthDate).format("YYYY-MM-DD");
      result.residentSince = moment(result.residentSince).format("YYYY-MM-DD");

      if (typeof result.deviceId === 'object' && result.deviceId.length > 0) {
        result.deviceInfo = result.deviceId.map(deviceId => {
          return rootGetters['device/peopertyMapping'](deviceId)
        });
      } else {
        result.deviceInfo = []
      }
      if (result.deviceInfo) {
        result.deviceAlertCount = result.deviceInfo.reduce((count, device) => {
          count += device.alertList?.length || 0;
          return count;
        }, 0)
      }
      // bedId
      if (result.bedId) {
        result.location = rootGetters['location/queryByBedId'](result.bedId);
      }
      result.daysResident = moment().diff(result.residentSince, 'days');

      result.duration = [0, 0, 0]
      result.duration[0] = Math.ceil(result.daysResident / 365)
      let leftdays = result.daysResident % 365
      result.duration[1] = Math.floor(leftdays / 30)
      result.duration[2] = leftdays % 30

      result.age = moment().diff(result.birthDate, 'years');
      // result.residentSince = moment(result.residentSince).format("YYYY-MM-DD hh:mm:ss");
      return result;
    },
    getContactListById: state => id => state.contacts.filter(contact => contact.patientId === id),
    // 之後需要依照選取的病房去篩選出符合的照服員
    getattendantByFilter: state => filter => {
      return state.attendants
    },
    queryPatient: (state, getters, rootState, rootGetters) => (filter, options = {}) => {
      let result = state.patients.reduce((resultAry, patient) => {
        let isMatched = true; // 當沒有filter時預設為 ture
        if (filter !== undefined && typeof filter === 'object') {
          isMatched = Object.keys(filter).every(property => {
            // return (patient[property] === undefined) ? false : filter[property] === patient[property]
            if (property === 'name') {
              return filter[property] === '' || (patient['familyName'] + patient['givenName']).includes(filter[property]);
            } else if (property === 'tag') {

              let targetTags = [];
              if (typeof filter[property] === 'string' && filter[property] !== '') {
                targetTags = [filter[property]]
              } else if (typeof filter[property] === 'object') {
                targetTags = [...filter[property]];
              }
              if (targetTags.length === 0) {
                return true;
              } else {
                return patient.tags.some(tag => targetTags.includes(tag.name));
              }
              //
            } else {
              return !filter[property] || patient[property] === filter[property]
            }
          })
        } else {
          // 當有 filter 時
          // isMatched = filter.every(property=>{
          //   let filterValue = filter[property];
          //   return true
          // })
        }
        if (options.formatOutput === true) { patient = getters.peopertyMapping(patient.id) }
        if (isMatched === true) { resultAry.push(patient); }
        return resultAry
      }, [])
      return result;
    },
    getlanguageCodes: state => state.languageCodes,
    getpositionCodes: state => state.positionCodes,
    getCertificateList: state => state.certificateList,
    getCaList: state => state.caList,
    getPractitionerCertificate: (state, getters) => filter => {
      let result = getters.getFilterData('certificates', filter);
      result.map(item => {
        item.certificateName = { ...state.certificateList.find(cert => cert.code === item.certificateCode) }?.name || '不明'
        item.caName = { ...state.caList.find(ca => ca.code === item.caCode) }?.name || '不明'
      })
      return result;
    },
    getPractitionerDetail: (state, getters) => (filter) => {
      let list = getters.getFilterData('practitioners', filter);
      let result = list.map(item => {
        item = { ...item }
        item.gender = state.genderCodes.find(gender => gender.code === item.gender)?.name || '未設定';
        item.position = state.positionCodes.find(gender => gender.code === item.position)?.name || '未設定';
        item.birthDate = moment(item.birthDate).format("YYYY-MM-DD");
        item.age = moment().diff(item.birthDate, 'years');

        item.languages = item.languages?.map(language => {
          return state.languageCodes.find(item => item.code === language)?.name || undefined
        })
        item.certificateList = getters.getPractitionerCertificate({ practitionerId: item.id })

        return item
      })
      return result;
    },
    // getCaByPractitionerId: state => id => {
    //   return state.certificates.filter(cert=>{
    //     return cert.practitionerId === id
    //   })
    // },

    getFilterData: state => (name, filter = {}) => {
      if (state[name] === undefined) {
        return []
      }
      if (filter && Object.keys(filter).length === 0) {
        return state[name];
      }

      let result = state[name].filter(item => {
        let isMatched = Object.keys(filter).every(property => {

          if (filter[property] === '') {

            return true
          }
          if (property === 'name' || property === 'username') { // 
            return filter[property] === '' || item[property].includes(filter[property]);
          } else if (property === 'certificateCode') { // 用證照來搜尋
            return item.qualifications.includes(filter[property]);
          } else {
            return item[property] === filter[property]
          }
        })
        return isMatched
      })

      return result
    }
  }
}

