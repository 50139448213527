import { UbusRequest } from "@/helper/ubus"
export default {
  namespaced: true,
  state() {
    let device_log = {}; // 排遺記錄存區
    let device_status = []; // 設備清單 
    let device_statistic = []; // 設備狀態 
    // 以上三個 state 對應到同名資料表
    return {
      count: 1,
      notifyConditions: [],
      show_offline: true,
      device_filter: '',
      // 每個設備的排遺記錄，原先是透過 getDeviceLogByComponent 更新，後改成由在 component 裡面直接呼叫 UbusRequest 完成
      // 這樣程式就無法進中管理，應該要搬回這邊才對 ... 
      device_log,
      device_status, // mqtt 頁面中 device-block 使用的位置
      device_statistic, // 每一個設備的數據，會由mqtt更新
    }
  },
  mutations: {
  },
  actions: {
    // 每秒 更新一次 ... 這個值可以再改到後台去調整 ... 
    getDeviceStatus: async ({ rootState }, params) => {
      let argument = { namespace: 'device_new', procedure: 'get_device_list' }
      let reqResult = await UbusRequest(argument)
      if (reqResult.list) {
        rootState.device.online_status = reqResult.list;
      }
    },
    // 把資料庫的數據更新到 state 中的 device_statistic
    getDeviceStatistic: async ({ rootState }) => {
      let argument = { namespace: 'device_new', procedure: 'get_device_statistic' }
      let result = await UbusRequest(argument)
      rootState.device.device_statistic = result.list;
    },
    // 把資料庫的數據更新到 state 中的 urine_bag_statistic
    getUrineBagStatistic: async ( { rootState }) => {
      console.log('getUrineBagStatistic has been called');
      let argument = { namespace: 'device_new', procedure: 'get_urine_bag_statistic' }
      let result = await UbusRequest(argument)
      console.log('result.list:', result.list);
      rootState.device.urine_bag_statistic = result.list;
    },

    // 此項目尚未實作 ... 
    getNotificationList: async ({ rootState }) => {
      let argument = { namespace: 'device', procedure: 'get_notification_list' }
      let data = await UbusRequest(argument)
      if (typeof (data.list) === 'object') {
        rootState.device.notifyConditions = data.list
      }
    },
    // data: {name, device_mac, method: insert or update}
    setDeviceUserName: async (context, params) => {
      let data = {}
      if (params.method === 'insert') {
        data = { method: 'insert', name: params.name, device_mac: params.device_mac };
      } else {
        data = { method: 'update', device_mac: params.device_mac, update_values: { name: params.name } };
      }
      const argument = { namespace: 'device_new', procedure: 'users', data }
      let res = await UbusRequest(argument)
      return res
    },
    getDeviceUserNames: async () => {
      const params = { namespace: 'device_new', procedure: 'users' }
      const res = await UbusRequest(params);

      let output = {};
      if (res.list) {
        output = res.list.reduce((result, row) => {
          result[row.device_mac] = row.name;
          return result
        }, {});
      }
      // return new Promise(resolve => resolve(output))
      return output
    },
    queryDeviceUser: async (context, { device_mac, event_t }) => {
      let name;
      const data = { device_mac, start_time: event_t, end_time: event_t }
      const params = { namespace: 'device_new', procedure: 'query_user', data }
      let res = await UbusRequest(params);
      if (res.list) {
        name = res.list[0].name;
      }
      return name
    }
  },
  // async getDeviceLog(context, params) {
  //   const mac = params.mac;
  //   let start_t = params.start_t;
  //   let end_t = params.end_t;
  //   let data = {}
  //   let argument = { namespace: 'device', procedure: "get_log", data: { mac, start_t, end_t } }
  //   data = await UbusRequest(argument)
  //   return data.log || {}
  // },
  // 好像沒在用了 ... 
  // async getDeviceInfo(context, id) {
  //   let params = { opt: "get", table: "device_info", filter: { where: "targetTemp > ? and user_id = ?", param: [28, id] } }
  //   let argument = { namespace: 'device', procedure: 'get', data: params }
  //   const data = await UbusRequest(argument)
  //   if (data.error?.code) {
  //     //
  //   } else {
  //     data.result[1].result.forEach((info) => {
  //       let target = context.rootState.device.userList.filter(d => d.id == info.user_id)
  //       if (target.length > 0) {
  //         let deviceInfo = target[0].deviceInfo
  //         deviceInfo.urine = parseInt(info.urine)
  //         deviceInfo.stool = parseInt(info.stool)
  //         deviceInfo.targetTemp = parseFloat(info.targetTemp)
  //         deviceInfo.currentTemp = parseFloat(info.currentTemp)
  //         deviceInfo.operating = (info.operating === '1')
  //       }
  //     })
  //   }
  // },
  getters: {
    // 已無使用 雁該要把頁面那個搬過來 ：
    // getDeviceLog: (state) => (mac_address) => {
    //   return state.device_log[mac_address] || {};
    // },

    getNotificationList: (state) => {
      return state.notifyConditions;
    },

  }
}