import { UbusRequest } from "@/helper/ubus"
export default {
    namespaced: true,
    state() {

        const comfirm_callback = () => {
            return true
        }

        const cancel_callback = () => {
            return false
        }

        const header = '確認';
        const body = '確認刪除？';

        const modal_show = false;
        const size = 'lg';
        const confirm_context = '確認';
        const cancel_context = '取消';
        const modal_result = undefined;
        const auto_close_countdown = 10;

        return {
            comfirm_callback,
            cancel_callback,
            confirm_context,
            cancel_context,
            modal_show,
            header,
            body,
            size,
            modal_result,
            auto_close_countdown
        }

    },
    mutations: {
        set_modal_result: (state, result) => {
            if (state.modal_show === true) {
                state.modal_result = result === true;
                state.modal_show = false;
            }

        }
    },
    actions: {
        confirm_modal: async ({ state }, params = {}) => {
            let { auto_close, header, body, confirm_context, cancel_context, size } = params;

            auto_close = parseInt(auto_close);

            state.modal_result = undefined;
            state.auto_close_countdown = auto_close || 10;
            state.size = size || 'lg';
            state.header = header || '確認';
            state.body = body || '確認作業？';
            state.confirm_context = confirm_context || '確認';
            state.cancel_context = cancel_context || '取消';

            state.modal_show = true
            return new Promise((resolve, reject) => {
                let interval_id = setInterval(() => {
                    if (state.modal_show === true && state.auto_close_countdown > 0) {
                        state.auto_close_countdown--;
                    }
                    if (state.auto_close_countdown === 0 || state.modal_show === false) {
                        if (interval_id) {
                            clearInterval(interval_id)
                        }
                        if (state.modal_result === true) {
                            resolve(true);
                        } else {
                            state.modal_show = false; // 關閉 modal .
                            resolve(false);
                        }
                    }
                }, 1000);
            })
        },
    },
    getters: {
        getModal: (state) => {
            const modal = {
                header: state.header,
                body: state.body,
                size: state.size,
                confirm_context: state.confirm_context,
                cancel_context: state.cancel_context
            }
            if (state.auto_close_countdown > 0) {
                modal.cancel_context += '(' + state.auto_close_countdown + ')'
            }
            return modal
        }
    }
}