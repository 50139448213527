<template>
  <div class="bg-light mb-3">
    <div class="row fs-4 text-nowrap">
      <div class="col ms-3 mt-3">
        <a href="#" @click="goBack"><font-awesome-icon icon="angle-left" /></a>
      </div>
      <div class="col-6 text-center mt-3">
        <div class="d-block d-lg-none">
          <img class="mb-0" :src="logo_img" style="height: 30px" />
          <!-- <img class="mb-0" src="@/assets/logo.png" style="height: 30px" /> -->
          {{ $t('institution-name-short') }}
        </div>
        <div class="d-none d-lg-block">
          <!-- <img class="mb-0" src="@/assets/logo.png" style="height: 30px" /> -->
          <img class="mb-0" :src="logo_img" style="height: 30px" />
          {{ $t('institution-name-full') }}
        </div>
      </div>
      <div class="col me-2 mt-3">
        <div class="text-end d-block d-lg-none">{{ time_short }}</div>
        <div class="text-end d-none d-lg-block">{{ time_full }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, computed, onBeforeUnmount } from "vue";
import dateFormat from "dateformat";

import { format } from "date-fns";
import { zhTW, enUS, ja } from 'date-fns/locale'

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const router = useRouter();
let store = useStore();
const { locale } = useI18n();

const current_datetime = computed(() => store.state.config.current_datetime);

const localeMapping = { 'zh-TW': zhTW, 'en-US': enUS, 'ja-JP': ja };

let timeUpdateIntervalId;

onMounted(() => {
  timeUpdateIntervalId = setInterval(() => {
    // current_datetime.value = new Date();
    store.commit('config/updateCurrentDatetime');
  }, 1000);
});

onBeforeUnmount(() => {
  if (timeUpdateIntervalId) {
    console.log('clearInterval:', timeUpdateIntervalId);
    clearInterval(timeUpdateIntervalId);
    timeUpdateIntervalId = undefined;
  }
})

const time_short = computed(() => {
  return format(current_datetime.value, 'pp', { locale: localeMapping[locale.value] })
})

const time_full = computed(() => {
  return format(current_datetime.value, 'PPPpp', { locale: localeMapping[locale.value] })
})

const logo_img = computed(() => '/img/' + store.getters['config/getValue']('dashboard', 'header_logo'))

const goBack = () => {
  // router.back();
  router.push("/");
};

</script>

<style scoped>
/* .header {
  position: sticky;
  top: 0px;
  z-index: 1039;
  height: 60px;
} */
</style>