import { ref } from "vue"
// import * as mqtt from "mqtt/dist/mqtt.min"
import mqtt from "mqtt"
import { useRoute } from "vue-router"
import store from '@/store'
import { genRanHex } from "@/helper/daxin_utils"


class mqtt_connections {

    constructor() {
    }

    _name = genRanHex(6);
    _connections = {};
    _functions = {};
    _subscribes = [];
    _debug = true;
    _route;

    // 目前應該是沒有連線到多個不同的伺服器需求，先做單獨一個就好
    connect(name, options = {}) {

        if (options.debug == true) {
            this._debug = true;
        }

        if (name !== undefined) {
            this._name = name
        }

        let mqtt_server_cfg = store.state.config.mqtt_server;

        // let broker = options.broker || mqtt_server_cfg.broker
        // let port = options.port || mqtt_server_cfg.port
        // let protocol = options.protocol || mqtt_server_cfg.protocol

        if (!this._connections[name]) {
            // not exists
            options.clientId = options.clientId || 'dxdb_' + this._name + '_' + genRanHex(4);
            options.keepalive = options.keepalive || 300;
            options.clean = options.clean || true;
            options.connectTimeout = options.connectTimeout || 4000;
            options.username = options.username || mqtt_server_cfg.username;
            options.password = options.password || mqtt_server_cfg.password;
            options.host = options.host || mqtt_server_cfg.broker;
            options.hostname = options.host || mqtt_server_cfg.broker;
            options.protocol = options.protocol || mqtt_server_cfg.protocol;
            options.port = options.port || mqtt_server_cfg.port;
            // options.defaultProtocol = 'mqtt';
            options.debug = false;

            if (typeof (options.route) === 'object' && options.route.name !== undefined) {
                this._route = options.route; // 這個是幹嘛的？
            }
            // this._connections[name] = mqtt.connect(`${protocol}://${broker}:${port}`, options);
            try {
                this._connections[name] = mqtt.connect(options);
            } catch (e) {
                //
            }

            // 預設的 callback 函式 ... 
            this._connections[name].on('message', function (topic, payload) {
                try {
                    let msg = JSON.parse(payload);
                    if (this.options.debug === true) {
                        //
                    }
                } catch (e) {
                    //
                }
            })

            this._connections[name].on('error', (err) => {
                this._connections[name].end();
            })
            this._connections[name].on('connect', function (a) {
            }, {}, () => { });
        }
        return this._connections[name]
    }
    // 沒辦法使用 property 儲存 callbacks 似乎也沒什麼必要另外處理訂閱的部份... 
    add_listener(topic, cb, options) {
        if (this._subscribes.includes(topic) === false) {
            this._connections[this._name].subscribe(topic);
            this._subscribes.push(topic);

            if (typeof (cb) === 'function') {
                this._connections[this._name].on('message', (message_topic, payload) => {
                    try {
                        let _limit_route;
                        if (options !== undefined) {
                            if (options.limit_route) {
                                _limit_route = options.limit_route;
                            }
                        }

                        let msg = JSON.parse(payload);
                        let regex_pattern = topic.replace(/\/\+\//, '/(.*?)/').replace(/#$/, '(.*?)')
                        regex_pattern = '^' + regex_pattern + '$';

                        if (this._route?.name === undefined || _limit_route === undefined || _limit_route === this._route.name) {
                            if (message_topic.match(regex_pattern)) {
                                cb(msg, message_topic);
                            }
                        } else {
                            //
                        }
                    } catch (e) {
                        //
                    }
                })
            }
        } else {
            //
        }
    }

    get_status(_name) {
        let name = _name || this._name;
        return this._connections[name].connected;
    }

    publish(payload, topic, cb) {
        if (typeof (payload) == 'object') {
            payload = JSON.stringify(payload);
        }
        if (typeof (payload) !== 'string') {
            payload = '';
        }
        this._connections[this._name].publish(topic, payload, { qos: 1 }, (err) => {
            if (typeof cb === "function" && !err) {
                cb();
            }
        });
    }

}

let mqtt_helper = new mqtt_connections();

export { mqtt_helper as mqtt }

