import { v4 as uuid } from 'uuid';
import { getChineseNumber as getFloorName } from '@/helper/daxin_utils';
import { getChineseNumber } from '../../helper/daxin_utils';

export default {
  namespaced: true,
  state() {
    const sortBy = []; // 排序 若有 no 則以 no ，沒有再找 name ， 若不需要排序則設定為 空陣列

    const buildings = [
      {
        id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        name: "明倫堂",
        created_t: 'Fri Jul 01 2022 00:00:00 GMT+0800 (GMT)',
        end_t: undefined,
        order: 1,
      },
      {
        id: "dab37b7a-7145-41fe-ae52-40b997610422",
        name: "弘道堂",
        created_t: 'Fri Jul 01 2022 00:00:00 GMT+0800 (GMT)',
        end_t: undefined,
        order: 2,
      },
      {
        id: "f2190a64-7b9f-43de-b3f7-b3e2c46a89ae",
        name: "仁愛堂",
        created_t: 'Fri Jul 01 2022 00:00:00 GMT+0800 (GMT)',
        end_t: 'Mon Jul 11 2022 12:34:56 GMT+0800 (GMT)',
        order: 2,
      },
    ];

    const floors = [
      { id: "4092769b-9f82-4dae-81d0-2b1b7c569d9b", building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b", name: "一樓", "description": "長照中心", no: 1, order: 1, floor: 1 },
      { id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3", building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b", name: "二樓", "description": "一般病房", no: 2, order: 2, floor: 2 },
      { id: "c0d8aae0-c835-497e-8f6e-dbaba433ee70", building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b", name: "三樓", "description": "", no: 3, order: 3, floor: 3, },
      { id: "458bdd6c-b4fb-40ea-bb36-7ed6944ef4f8", building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b", name: "五樓", "description": "一般病房", no: 5, order: 4, floor: 5, },
      { id: "ca3cfd5f-10e8-4c1d-8baa-ae17253e894b", building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b", name: "六樓", "description": "一般病房", no: 6, order: 5, floor: 6, },
      { id: "18ad2d42-716a-4900-b09e-cdc9d8b3e761", building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b", name: "七樓", "description": "一般病房", no: 7, order: 6, floor: 7, },
      { id: "0abc0917-4873-4de2-97b3-78a17e990fcd", building_id: "dab37b7a-7145-41fe-ae52-40b997610422", name: "一樓", "description": "一般病房", no: 1, order: 1, floor: 1, },
      { id: "d0f0cc42-c7ea-4683-a37b-f023f0a31f78", building_id: "dab37b7a-7145-41fe-ae52-40b997610422", name: "二樓", "description": "長照中心", no: 2, order: 2, floor: 2, },
    ]

    const wards = [
      {
        id: "b383dea2-6fdf-46a1-b823-b54109d1e0eb",
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        name: "1A病房",
        floor_id: "4092769b-9f82-4dae-81d0-2b1b7c569d9b",
        order: 1,
        no: 1
      },
      {
        id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        name: "2A病房",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        order: 1,
        no: 1
      },
      {
        id: "1e94c3c4-999d-4a7d-83ef-9f25cdade5b1",
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        name: "2B病房",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        order: 2,
        no: 2,
      },
      { // 弘道堂
        id: "4dbca36c-b10b-4a5a-8983-0d0d025a1707",
        building_id: "dab37b7a-7145-41fe-ae52-40b997610422",
        name: "2B病房",
        floor_id: "d0f0cc42-c7ea-4683-a37b-f023f0a31f78",
        no: 1,
        order: 1,
      },
    ];
    const rooms = [
      { // 弘道堂 - 2B病房
        building_id: "dab37b7a-7145-41fe-ae52-40b997610422",
        floor_id: "d0f0cc42-c7ea-4683-a37b-f023f0a31f78",
        id: "13267c18-d9ef-42af-a42d-d9ca3d058ca0",
        ward_id: "4dbca36c-b10b-4a5a-8983-0d0d025a1707",
        name: "一號房",
        no: 1,
        order: 1
      },
      { // 弘道堂 - 2B病房
        building_id: "dab37b7a-7145-41fe-ae52-40b997610422",
        floor_id: "d0f0cc42-c7ea-4683-a37b-f023f0a31f78",
        id: "7388bbbb-b0cc-43f4-afc0-fea9f24f6162",
        ward_id: "4dbca36c-b10b-4a5a-8983-0d0d025a1707",
        name: "三號房",
        no: 3,
        order: 2
      },
      { // 明倫堂 - 2A病房
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        id: "4cc9d2f9-cf00-44b1-9d68-f042476ab888",
        ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        name: "一號房",
        no: 1,
        order: 3
      },
      { // 明倫堂 - 2A病房
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        id: "d33b9e60-c29a-41d9-9118-64f938fa670f",
        ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        name: "二號房",
        no: 2,
        order: 4
      },
      { // 明倫堂 - 2A病房
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        id: "ad3c05dd-21c1-48f9-a29e-ecfdf9e34072",
        ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        name: "三號房",
        no: 3,
        order: 5
      },
      { // 明倫堂 - 2A病房
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        id: "30f43eca-68a4-48a4-b599-f86e35bb1161",
        ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        name: "五號房",
        no: 5,
        order: 6
      },
      { // 明倫堂 - 2A病房
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        id: "3d0ff8ad-0d7d-447a-af51-f29ca437e186",
        ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        name: "六號房",
        no: 6,
        order: 7
      },
      { // 明倫堂 - 2A病房
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        id: "3cf34038-3822-43dd-9891-309a21bab6a5",
        ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        name: "七號房",
        no: 7,
        order: 8
      },
      { // 明倫堂 - 2A病房
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        id: "6d3d1746-507b-4adc-889e-0e9e8e7e9811",
        ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
        name: "八號房",
        no: 8,
        order: 9
      },
      { // 明倫堂 - 2B病房 
        building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
        floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
        ward_id: "1e94c3c4-999d-4a7d-83ef-9f25cdade5b1",
        id: "cc79dd83-76bf-4212-bc17-4cb3a78366eb",
        name: "一號房",
        no: 1,
        order: 10
      },
    ];

    const beds = [
      {
        id: "a7854e5d-a33d-4816-93be-0dd13258160a",
        room_id: "4cc9d2f9-cf00-44b1-9d68-f042476ab888",
        name: "1A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "1423ac1f-0148-4071-a339-b324d4b0806c",
        room_id: "4cc9d2f9-cf00-44b1-9d68-f042476ab888",
        name: "1B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "69caf80d-072a-4a4e-9b84-590d8b417a62",
        room_id: "4cc9d2f9-cf00-44b1-9d68-f042476ab888",
        name: "1C",
        no: 3,
        order: 3,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "c0e5bfe2-6191-4a2d-aaf1-dad96d0b8372",
        room_id: "d33b9e60-c29a-41d9-9118-64f938fa670f",
        name: "2A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "f007bf71-9c37-4493-b4c7-0a6ecfccda60",
        room_id: "d33b9e60-c29a-41d9-9118-64f938fa670f",
        name: "2B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "daec12aa-0083-499d-b966-7f038792d55c",
        room_id: "d33b9e60-c29a-41d9-9118-64f938fa670f",
        name: "2C",
        no: 3,
        order: 3,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "c91897ad-9e2f-40cb-b626-aca633ebc39b",
        room_id: "ad3c05dd-21c1-48f9-a29e-ecfdf9e34072",
        name: "3A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "d714f322-9fba-4744-851f-cc262112e11b",
        room_id: "ad3c05dd-21c1-48f9-a29e-ecfdf9e34072",
        name: "3B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "bc58b0ed-581e-401d-a2ee-f3de299b03cd",
        room_id: "ad3c05dd-21c1-48f9-a29e-ecfdf9e34072",
        name: "3C",
        no: 3,
        order: 3,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "a400fefc-b41b-49e1-b398-54212b8b07a1",
        room_id: "30f43eca-68a4-48a4-b599-f86e35bb1161",
        name: "5A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "7cf4e7f6-8cf6-46a8-9fa4-5199770cf7ef",
        room_id: "30f43eca-68a4-48a4-b599-f86e35bb1161",
        name: "5B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "2eb57ee4-55ad-418e-ba9b-46df518ad2a9",
        room_id: "30f43eca-68a4-48a4-b599-f86e35bb1161",
        name: "5C",
        no: 3,
        order: 3,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },

      {
        id: "7175db37-4e74-4b6d-a455-83669a956e75",
        room_id: "3cf34038-3822-43dd-9891-309a21bab6a5",
        name: "7A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "4164335d-6756-4a4b-9b48-7b763f152584",
        room_id: "3cf34038-3822-43dd-9891-309a21bab6a5",
        name: "7B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "e044b542-1994-4a1f-89cc-87a4f2e49070",
        room_id: "3cf34038-3822-43dd-9891-309a21bab6a5",
        name: "7C",
        no: 3,
        order: 3,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "8626c9bc-7d88-4ab5-94ec-659d4b567b24",
        room_id: "6d3d1746-507b-4adc-889e-0e9e8e7e9811",
        name: "8A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "76fbd5fe-2e29-4140-ade0-0df4c3c4fd27",
        room_id: "6d3d1746-507b-4adc-889e-0e9e8e7e9811",
        name: "8B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "6f03e8e6-9952-4d80-8ea7-195a4138d429",
        room_id: "6d3d1746-507b-4adc-889e-0e9e8e7e9811",
        name: "8C",
        no: 3,
        order: 3,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      { // 明倫堂 - 2B病房 1號房
        id: "cd14c886-bfc7-4168-aae7-1b964e8aac74",
        room_id: "cc79dd83-76bf-4212-bc17-4cb3a78366eb",
        name: "1A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      {
        id: "baebeb51-9822-4eef-891b-4c6282d8249a",
        room_id: "cc79dd83-76bf-4212-bc17-4cb3a78366eb",
        name: "1B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      { //  
        id: "9b4c5596-2bb3-459a-89fe-e4dc0992237f",
        room_id: "cc79dd83-76bf-4212-bc17-4cb3a78366eb",
        name: "1C",
        no: 3,
        order: 3,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      { // 弘道堂 - 2B病房 - 一號房
        id: "bcec64bc-9388-445a-9cb8-d8911738e5ad",
        room_id: "13267c18-d9ef-42af-a42d-d9ca3d058ca0",
        name: "1A",
        no: 1,
        order: 1,
        patient: '12345',
        status: 'occupied',
        status_reason: 'in-use',
      },
      { // 弘道堂 - 2B病房 - 一號房
        id: "7cda7c23-95ec-46f8-ac3a-4e56e7854395",
        room_id: "13267c18-d9ef-42af-a42d-d9ca3d058ca0",
        name: "1B",
        no: 2,
        order: 2,
        patient: '12345',
        status: 'occupied',
        status_reason: 'in-use',
      },
      { // 弘道堂 - 2B病房 - 一號房
        id: "fb623e9f-cb93-43d5-908e-0bdb18e5b049",
        room_id: "13267c18-d9ef-42af-a42d-d9ca3d058ca0",
        name: "1C",
        no: 3,
        order: 3,
        patient: '12345',
        status: 'occupied',
        status_reason: 'in-use',
      },
      { // 弘道堂 - 2B病房 - 一號房
        id: "f3082cff-928f-4e04-8f1f-5f72ddc48695",
        room_id: "13267c18-d9ef-42af-a42d-d9ca3d058ca0",
        name: "1D",
        no: 4,
        order: 4,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      { // 弘道堂 - 2B病房 - 三號房
        id: "a6c35d3c-f217-439f-a50a-fd4a2873647b",
        room_id: "7388bbbb-b0cc-43f4-afc0-fea9f24f6162",
        name: "3A",
        no: 1,
        order: 1,
        patient: undefined,
        status: 'unavailable',
        status_reason: 'maintainace',
      },
      { // 弘道堂 - 2B病房 - 三號房
        id: "c951ba1c-8644-4cf3-9cd0-5353e3ad5959",
        room_id: "7388bbbb-b0cc-43f4-afc0-fea9f24f6162",
        name: "3B",
        no: 2,
        order: 2,
        patient: undefined,
        status: 'available',
        status_reason: '',
      },
      { // 弘道堂 - 2B病房 - 三號房
        id: "defdcd81-0310-4d72-8bdc-6a731ca8a0e2",
        room_id: "7388bbbb-b0cc-43f4-afc0-fea9f24f6162",
        name: "3C",
        no: 3,
        order: 3,
        patient: 'b4783286-d9f2-4350-82c2-75a980bce46c',
        status: 'occupied',
        status_reason: 'in-use',
      },
    ];
    // 1. 當沒有資料時就不需要篩選
    // 2. 當有資料時就要把當前資料做為下層的層選條件之一
    // 3. filters 的格式請參考 filterData 的參數... ( 除去 target )
    //   mode: and / or 
    //   sortBy: { name: "desc" }
    //   filter: { filter: { id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b" } },
    // 其實我不太記得為什麼這邊要寫成陣列，目前這樣的寫法會變成多次撈取符合的資料
    // 真的會這樣使用嗎？ 
    const filters = {
      // buildings: { filter: { id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b" } },
      // floors: { filter: { id: "4092769b-9f82-4dae-81d0-2b1b7c569d9b" } },
      buildings: {},
      floors: {},
      wards: {},
      rooms: {},
      beds: {},
    };

    const selected = { building: '', floor: '', ward: '', room: '', bed: '' };

    const reasonCodes = {
      bed:
      {
        occupied: [
          { code: 'in-use', name: '現正使用中' },
          { code: 'reserved', name: '預約保留中' },
        ],
        unavailable: [
          { code: 'maintainace', name: '維護中' },
          { code: 'malfunction', name: '設備異常' },
          { code: 'repair', name: '設備維修' },
          { code: 'sterilization', name: '滅菌' },
          { code: 'disinfection', name: '清消' },
          { code: 'cleaning', name: '設備清潔' },

        ]
      }

    }
    const statusCodes = {
      bed: [
        { code: '', name: '請選擇', disabled: true },
        { code: 'occupied', name: '已分派' },
        { code: 'available', name: '可使用' },
        { code: 'unavailable', name: '不可使用' },
        { code: 'retired', name: '已除疫' },
      ]
    }

    return {
      sortBy, buildings, floors, wards, rooms, beds, filters, selected
    }
  },
  mutations: {
    sortBy(state, payload) {

      if (typeof payload === 'string') {
        state.sortBy.push(payload);
      } else if (Array.isArray(payload)) {
        state.sortBy = [...state.sortBy, ...payload];
      }

    },
    // 對 state 的 filter 進行操控
    // payload: { building: {"id":"f12a3f7b-d454-43d9-8558-c5ddecfdb62b"}}
    setFilter(state, payload) {

      for (let target in payload) {
        for (let property of ['filter', 'sortBy']) {

          let sourceFilter = payload[target][property];
          // let sourceSortBy = payload[target].sortBy;
          if (sourceFilter !== undefined) {

            state.filters[target][property] = state.filters[target][property] || {}
            let targetFilter = state.filters[target][property]
            if (Object.keys(sourceFilter).length > 0) {
              for (let key in sourceFilter) {
                targetFilter[key] = sourceFilter[key];
              }
            } else {
              state.filters[target][property] = {}
            }
          }
        }
      }
    },
    setSelectedTarget: (state, data) => {
      Object.keys(state.selected).forEach((name) => {

        if (data[name] !== undefined) {

          state.selected[name] = data[name];
        }
      });
    },
    addBuilding: (state, name) => {
      // 格式 .. 
      const uid = uuid();
      const order = state.buildings.length + 1;
      const data = { id: uid, name, order };
      state.buildings.push(data);
    },
    editBuilding: (state, { name, id }) => {
      const target = state.buildings.find(building => building.id === id)
      if (target) {
        target.name = name;
      }
    },
    addFloor: (state, data) => {
      // 格式 .. 
      //{ id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3"
      //, building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b"
      //, name: "二樓", "description": "一般病房", no: 2, order: 2, floor: 2 },
      // const { order, building_id, description, floor } = data;

      state.floors.push(data);
    },
    // { 
    //   id: "4dbca36c-b10b-4a5a-8983-0d0d025a1707",
    //   building_id: "dab37b7a-7145-41fe-ae52-40b997610422",
    //   name: "2B病房",
    //   floor_id: "0abc0917-4873-4de2-97b3-78a17e990fcd",
    //   order: 4,
    // },
    addWard: (state, data) => { // eslint-disable-line
      data.id = uuid();
      // 取得目前的order ....
      const currentOrder = state.wards.filter(item => item.floor_id === data.floor_id).sort((a, b) => b.order - a.order);
      // .sort((a,b)=>b.order-a.order)
      let order = -1;
      if (currentOrder.length === 0) {
        order = 1
      } else {
        order = currentOrder[0].order + 1;
      }
      data.order = order;
      state.wards.push(data);
    },
    editWard: (state, { name, id }) => {
      const target = state.wards.find(ward => ward.id === id)
      if (target) {
        target.name = name;
      }
    },
    addRoom: (state, data) => { // eslint-disable-line
      state.rooms.push(data);
    },
    addBed: (state, data) => { // eslint-disable-line
      state.beds.push(data);
    }
  },
  actions: {
    setSelectedTarget: (ctx, { target, value }) => {
      const properties = ['building', 'floor', 'ward', 'room', 'bed'];
      // let i = 1;
      const data = properties.reduce((acc, name) => {
        if (name === target) {
          acc[name] = value;
        } else if (Object.keys(acc).length > 0) {
          acc[name] = '';
        }
        return acc;
      }, {});
      ctx.commit('setSelectedTarget', data);
    },
    // { // 明倫堂 - 2A病房
    //   building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
    //   floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
    //   id: "4cc9d2f9-cf00-44b1-9d68-f042476ab888",
    //   ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
    //   name: "一號房",
    //   no: 1,
    //   order: 3
    // },
    addNewWard: (ctx, { building_id, floor_id, name }) => {
      // 取得 building_id / floor_id


      // 取得 order 
      const currentOrder = ctx.state.wards.filter((ward) => ward.floor_id === floor_id).sort((a, b) => a.sort > b.sort).pop()?.order || 0;
      const currentNo = ctx.state.wards.filter((ward) => ward.floor_id === floor_id).sort((a, b) => a.no > b.no).pop()?.no || 0;
      const params = { id: uuid(), building_id, name, floor_id, order: currentOrder + 1, no: currentNo + 1 };
      ctx.commit('addWard', params);
    },

    editWard: (ctx, { name, id }) => {

      ctx.commit('editWard')
    },

    addNewFloor: (ctx, data) => {
      data.id = data.id || uuid();
      data.name = getChineseNumber(data.floor) + '樓';
      const allFloors = ctx.state.floors.filter(item => item.building_id === data.building_id)
      const currentOrder = allFloors.sort((a, b) => b.order - a.order)[0]?.order || 0;
      data.order = currentOrder + 1;
      ctx.commit('addFloor', data);
      return true
    },
    // {
    //   building_id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b",
    //   floor_id: "8fa8856a-044f-4c2b-95b1-16742b46a0f3",
    //   id: "3d0ff8ad-0d7d-447a-af51-f29ca437e186",
    //   ward_id: "67f75a2e-8607-4f6f-8301-44bab947e41f",
    //   name: "六號房",
    //   no: 6,
    //   order: 7
    // }
    batchAddNewRoom: (ctx, data) => {
      return new Promise((resolve, reject) => {
        let currentOrder = 0;
        if (typeof data.rooms === 'object') {
          let currentRooms = ctx.state.rooms.filter(room => room.ward_id === data.ward_id)
          currentOrder = currentRooms.sort((a, b) => a > b).at(-1)?.order || 0;
          data.rooms.forEach((item, idx) => {
            currentOrder++
            item.order = currentOrder;
            item.building_id = data.building_id;
            item.floor_id = data.floor_id;
            item.ward_id = data.ward_id;
            ctx.commit('addRoom', item);
          })
        }
        resolve(true);
      })

    },
    // {
    //   id: "defdcd81-0310-4d72-8bdc-6a731ca8a0e2",
    //   room_id: "7388bbbb-b0cc-43f4-afc0-fea9f24f6162",
    //   name: "2C",
    //   no: 3
    //   order: 3
    // },
    batchAddNewBed: (ctx, data) => {
      return new Promise((resolve, reject) => {
        let currentOrder = 0;
        if (typeof data.beds === 'object') {
          let currentBeds = ctx.state.beds.filter(bed => bed.room_id === data.room_id)
          currentOrder = currentBeds.sort((a, b) => a > b).at(-1)?.order || 0;
          data.beds.forEach((item, idx) => {
            currentOrder++
            item.order = currentOrder;
            item.room_id = data.room_id;
            ctx.commit('addBed', item);
          })
        }
        resolve(true);
      })
    },
    deleteItemById: (ctx, { name, id }) => {
      // 找出項目 
      return new Promise((resolve, reject) => {
        if (ctx.state[name]) {
          const target = ctx.state[name].find(item => item.id === id);
          if (target) {
            target.end_t = new Date().toString();
          }
        }
        resolve(true);
      })
    },
    setPatientToBed: (ctx, { patientId, bedId }) => {
      let bed = ctx.state.beds.find(bed => bed.id === bedId);
      if (bed && bed.status === 'available') {
        bed.patient = patientId;
        bed.status = 'occupied'
        bed.status_reason = 'in-use'
        return true;
      }
      return false;
    },
    delPatientFromBed: (ctx, { patientId, bedId }) => {
      let bed = ctx.state.beds.find(bed => bed.id === bedId);
      if (bed && bed.patient === patientId) {
        bed.patient = '';
        bed.status = 'available'
        bed.status_reason = ''
        return true;
      }
      return false;
    }
  },
  getters: {

    queryByBedId: (state) => (bedId) => {
      let bed, room, ward, floor, building;
      bed = { ...state.beds.find(bed => bed.id === bedId) }
      if (bed.room_id !== undefined) {
        room = { ...state.rooms.find(room => room.id === bed.room_id) }
      }
      if (room.ward_id !== undefined) {
        ward = { ...state.wards.find(ward => ward.id === room.ward_id) }
      }
      if (ward.floor_id !== undefined) {
        floor = { ...state.floors.find(floor => floor.id === ward.floor_id) }
      }
      if (ward.building_id !== undefined) {
        building = { ...state.buildings.find(building => building.id === ward.building_id) }
      }

      return { bed, room, ward, floor, building }
    },
    // 這種寫法會變成一個函式 ... // id 一定是要是響應式數據才會觸發
    test: () => (id) => {

      return id + '號房';
    },


    // bedNumber: 28
    // floorCount: 5
    // id: "f12a3f7b-d454-43d9-8558-c5ddecfdb62b"
    // name: "明倫堂"
    // roomCount: 7
    // wardCount: 2


    // 資料階層 
    // 建築物 (building)
    //   ∟ 樓層 (floor) <- 此階層以上只用於權限設定 及 資料篩選用
    //       ∟ 病房 (ward) <- 頁面 儲存 building & floor_id
    //           ∟ 房間 (room) <- 篩選條件 儲存 ward_id
    //               ∟ 病床 (bed) <- 頁面 儲存 room_id

    // 用於篩選資料 
    // 1. target: 必須是 state 有的值
    // 2. filter: 可參對 state 內有的 property 進行篩選
    //            目前只能做完全比對 沒有什麼大於 小於 部份比對那種事..
    //            對於同一個屬性只能比對一次 ... 
    //            以上二點之後有空再修正了... 
    // 3. mode: 目前只有 and & or 二種模式
    //          and: 所有的條件比須要完全相符 
    //           or: 部份條件符合即可
    // 4. 排序這件事，吃 state.sortBy 這個陣列 ... 

    // let filterData = store.getters["location/filterData"];
    // let result = filterData({
    //   target: "rooms",
    //   mode: and / or 
    //   sortBy: { name: "desc" }
    //   filter: { id: "7388bbbb-b0cc-43f4-afc0-fea9f24f6162" },
    //   目前針對一個 property 只能下一種 條件... 

    filterData: (state) => params => {
      let { target, filter, mode, sortBy } = params;
      if (mode !== 'or') { mode = 'and'; }
      let result = [];
      if (Object.keys(state).includes(target)) {
        result = state[target].filter((item) => {
          let filtered = (mode === 'or') ? false : true; // or 查詢，預設為 false and: true
          let filterCount = 0;
          if (typeof filter === 'object') { // 有filter
            Object.keys(filter).forEach((property) => { // 把 filter的key取出進行迴圈
              let criteria = filter[property]; // 這是 filter 的 value
              if (typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, property)) {  // 如果
                filterCount++;
                if (mode === 'or') {
                  filtered = filtered || item[property] === criteria // 只要條件一個為真，就全部為真
                } else {
                  filtered = filtered && item[property] === criteria // 只要一個條件為假，則全部為假
                }
              }
            })
            return filterCount > 0 && filtered;
          } else {
            return true;
          }
        })
      }
      // 排序在滴家啦

      if (sortBy === undefined || (Array.isArray(sortBy) && sortBy.length === 0)) {
        sortBy = state.sortBy;
      }

      let sorted = false; // eslint-disable-line 
      for (let col in sortBy) {
        let sortType = sortBy[col];


        if (result.length > 0 && !sorted && Object.keys(result[0]).includes(col)) {

          if (typeof result[0][col] === 'number') {
            result.sort((a, b) => sortType === 'asc' ? a[col] - b[col] : b[col] - a[col]);
          } else if (typeof result[0][col] === 'string') {
            result.sort((a, b) => sortType === 'asc' ? a[col].localeCompare(b[col]) : b[col].localeCompare(a[col]))
          }
          sorted = true;
        }
      }
      return result;
    },

    // 回傳某個階層 並將上層的資訊flattenㄎ
    // 20220516 這樣寫會讓陣列失去排序，會用key排序 因此不可行...
    // option 有 filter 及 sortBy 二個選項

    getData: (state, getter) => options => { // eslint-disable-line no-unused-vars
      const tiers = ['buildings', 'floors', 'wards', 'rooms', 'beds'];
      const result = {};
      options = options || {};
      if (options.filter === undefined) { options.filter = true }
      if (options.sortBy === undefined) { options.sortBy = true }
      tiers.forEach((target) => {
        if (state[target] !== undefined) {
          let filter = state.filters[target];
          if (options.filter === true && Object.keys(filter).length > 0 && (filter.filter !== undefined || filter.sortBy !== undefined)) {
            filter.target = target;
            result[target] = getter.filterData(filter);
          } else { // 如果沒有 filter 時
            result[target] = state[target]
          }
        }
      });
      // options.filter = true 如果有篩選將次階的資料篩掉...
      if (options.filter === true) {
        result.floors = result.floors.filter(floor => {
          return result.buildings.some(building => building.id === floor.building_id);
        })
        result.wards = result.wards.filter(ward => {
          return result.floors.some(floor => floor.id === ward.floor_id);
        })
        result.rooms = result.rooms.filter(room => {
          return result.wards.some(ward => ward.id === room.ward_id);
        })
        result.beds = result.beds.filter(bed => {
          return result.rooms.some(room => room.id === bed.room_id);
        })
      }

      return result;
    },
    //  取得全部的資料(count)
    // options : 
    formatedData: (state, getter) => options => {
      options = options || {};
      if (options.filter === undefined) { options.filter = true }

      const raw = (getter.getData)(options);

      let formatedOutput = raw.buildings.map((building) => {
        let floorCount = 0;
        let wardCount = 0;
        let roomCount = 0;
        let bedCount = 0;
        building.floor = raw.floors.filter(floor => floor.building_id === building.id).map(floor => {
          floor.ward = raw.wards.filter(ward => ward.floor_id === floor.id).map(ward => {
            ward.room = raw.rooms.filter(room => room.ward_id === ward.id).map(room => {
              room.bed = raw.beds.filter(bed => bed.room_id === room.id).map(bed => {
                bedCount++
                return bed;
              })
              roomCount++
              return room
            })
            wardCount++
            return ward;
          })
          floorCount++
          return floor;
        })
        Object.assign(building, { bedCount, roomCount, wardCount, floorCount })
        return building;
      })


      return formatedOutput;

    },

    getBuildings: (state) => {
      return state.buildings;
    },
    // TODO: 這邊改寫成函式
    filteredBeds: (state, getters) => {

      const params = { target: 'beds', filter: { room_id: state.selected.room } };
      let result = getters.filterData(params);
      if (result.length > 0 && result.filter(item => item.id === state.selected.bed).length === 0) {
        state.selected.bed = result[0].id;
      }
      return result;
    },
    filteredRooms: (state, getters) => {

      const params = { target: 'rooms', filter: { ward_id: state.selected.ward } };
      let result = getters.filterData(params);
      if (result.length > 0 && result.filter(item => item.id === state.selected.room).length === 0) {
        state.selected.room = result[0].id;
      }
      return result;
    },
    filteredWards: (state, getters) => {

      const params = { target: 'wards', filter: { floor_id: state.selected.floor } };
      let result = getters.filterData(params);
      if (result.length > 0 && result.filter(item => item.id === state.selected.ward).length === 0) {
        state.selected.ward = result[0].id;
      }
      return result;
    },
    filteredFloors: (state, getters) => {

      const params = { target: 'floors', filter: { building_id: state.selected.building } };
      let result = getters.filterData(params);

      if (result.length > 0 && result.filter(item => item.id === state.selected.floor).length === 0) {
        state.selected.floor = result[0].id;
      }
      return result;
    },
    selectedTarget: state => state.selected,
    /*
      使用說明：此函式用於篩選 state 中的資料，只保留 filter 中有的資料

      可用參數： // 這二項說實在的不應該放在這裡... 
        showDeletedItem: 是否顯示已刪除的項目 (end_t 有值的項目)
        getAvailableBedsCount: 是否回傳可用的床數
    */
    getFilterData: state => (name, filter = {}) => { // 可多重選擇，也就是每個filter裡面的資料是「陣列」而不是「字串」
      // 2022/07/12 add showDeletedItem
      let { showDeletedItem, getAvailableBedsCount } = filter;
      showDeletedItem = showDeletedItem || false;
      getAvailableBedsCount = getAvailableBedsCount || false
      delete (filter.showDeletedItem);
      delete (filter.getAvailableBedsCount);

      let result;

      if (state[name] === undefined) {
        return []
      }
      if (filter && Object.keys(filter).length === 0) {
        result = state[name]; // 若沒有 filter 直接回傳整個物件
      } else {
        // 這邊的item有可能是陣列... 
        result = state[name].filter(item => {
          let isMatched = false

          isMatched = Object.keys(filter).every(propertyName => {
            if (!Array.isArray(filter[propertyName])) { // filter 非陣列
              if (filter[propertyName] === '') {
                return true
              }
              if (propertyName === 'name') { // 設備名稱用模糊比對
                return filter[propertyName] === '' || item[propertyName].includes(filter[propertyName]);
              } else { // 若查詢的資料沒有該property 直接回傳 true . 
                return item[propertyName] === undefined || item[propertyName] === filter[propertyName]
              }
            } else { // filter 為陣列

              // 如果是空陣列將不會回傳任何符合的結果 all result should be false 
              if (filter[propertyName].length === 0) {  // 如果為空陣列的話，直接回傳 true 
                return true
              } else {
                return filter[propertyName].some(targetValue => {
                  if (targetValue === '' || item[propertyName] === undefined) {
                    return true
                  } else {
                    return item[propertyName] === targetValue
                  }
                })
              }
            }
          })
          return isMatched
        })
      }
      // 若不顯示已刪除的項目則過濾掉 end_t 有值的項目
      if (showDeletedItem === false) {
        result = result.filter(item => {
          return item.end_t === undefined
        })
      }

      if (getAvailableBedsCount === true) {
        if (['buildings', 'floors', 'wards'].includes(name)) {
          result = result.map(item => {
            let rooms = state.rooms.filter(room => {
              return room[name.slice(0, -1) + '_id'] === item.id
            })

            let { bed_count, bed_available } = rooms.reduce((acc, room) => {
              state.beds.filter(bed => bed.room_id === room.id).forEach(bed => {
                acc.bed_count++
                if (bed.status === 'available') {
                  acc.bed_available++
                }
              })
              return acc
            }, { bed_count: 0, bed_available: 0 })

            item['bed_count'] = bed_count
            item['bed_available'] = bed_available
            return item
          })
        } else if (name === 'rooms') {
          result = result.map(room => {
            let { bed_count, bed_available } = state.beds.filter(bed => bed.room_id === room.id).reduce((acc, bed) => {
              acc.bed_count++;
              if (bed.status === 'available') {
                acc.bed_available++;
              }
              return acc
            }, { bed_count: 0, bed_available: 0 })
            room['bed_count'] = bed_count
            room['bed_available'] = bed_available
            return room
          })
        }

      }

      return result
    },
    // 在沒有任何篩選的情況下要撈出所有的 bed .

    filterRoom: (state, getters, rootState) => (filter) => {
      filter = { ...filter }

      // let { showAvailableBedsOnly } = filter;
      // showAvailableBedsOnly = showAvailableBedsOnly || false;
      // delete (filter.showAvailableBedsOnly);

      if (filter.room_id) {
        filter.id = filter.room_id
        delete (filter.room_id)
      }

      let rooms = getters.getFilterData('rooms', filter);

      rooms = rooms.reduce((acc, room) => {
        room.beds = state.beds.reduce((acc, bed) => {
          // if (showAvailableBedsOnly === true) {
          //   return bed.room_id === room.id && bed.patient === undefined
          // } else {
          //   return bed.room_id === room.id
          // }
          if (bed.room_id === room.id) {

            bed.status_name = [...rootState.config.statusCodes].find(status => status.code === bed.status)?.name || '';
            bed.reason_name = [...rootState.config.reasonCodes[bed.status] || []].find(reason => {
              return reason.code === bed.status_reason
            })?.name || '';
            if (bed.patient !== undefined) {
              let patient = [...rootState.administration.patients].find(patient => {
                return patient.id === bed.patient
              });
              if (patient) {
                bed.patient_name = patient.familyName + patient.givenName;
              }
            }
            if (filter.bed_id !== undefined && typeof filter.bed_id === 'object') {
              //
            }
            // 如果資料篩選有 bed_id 的話 ...
            if (!filter.bed_id
              || (typeof filter.bed_id === 'string' && bed.id === filter.bed_id)
              || (typeof filter.bed_id === 'object' && ([...filter.bed_id].length === 0 || filter.bed_id.includes(bed.id)))
            ) {
              acc.push({ ...bed })
            }
            // acc.push(bed)
          }
          return acc;
        }, [])

        // 取得建築物名稱 / 樓層 / 病房 資料

        room.building_name = state.buildings.find(building => building.id === room.building_id)?.name;
        room.floor_name = state.floors.find(floor => floor.id === room.floor_id)?.name;
        room.floor_description = state.floors.find(floor => floor.id === room.floor_id)?.description;
        room.ward_name = state.wards.find(ward => ward.id === room.ward_id)?.name;
        room.bed_availability =
          room.beds.reduce((count, bed) => {
            return count += (bed.status === 'available') ? 1 : 0;
          }, 0) +
          ' / ' + room.beds.length;

        if (room.beds.length > 0) {
          acc.push({ ...room });
        }
        // const tmp = [...rootState.config.statusCodes];
        // const test = tmp.find(item=>item.code=='available');

        // room.status_name = [...rootState.config.statusCodes].find(status=>status.code === room.status)?.name || '';
        // room.reason_name = rootState.config.reasonCodes.find(reason=>reason.code === room.reason)?.name || '';
        return acc;
      }, [])
      return rooms

    },
    /*
      2023-01-07 以下為改用真實新增資料介接 ... 
    */
    getBuildingNameById: (state) => id => state.buildings.find(building => building.id === id)?.name,
    getFloorNameById: (state) => id => state.floors.find(floor => floor.id === id)?.name,
    getFloorDescriptionById: (state) => id => state.floors.find(floor => floor.id === id)?.description,
    getWardNameById: (state) => id => state.wards.find(ward => ward.id === id)?.name,
    /*
      2023-01-06 由 filterRoom 修改而來 ... 這個要做的事情有點多
      1. 要多撈取 有設備的 病房 
      2. 還要在每個病房裡面顯示是否有告警 ..
      params 提供下列選項
        showOnlyAssignedRoom: 只顯示有住入住的房間
        其他還沒想到，上面也還沒實作 ... 
      回傳 資料格式 
        {
          buildings: [
            {
              id: "xxxx", 
              name: "弘道堂"
              floors: [
                {
                  id: "xxxx",
                  name: "二樓",
                  description: "長照中心",
                  wards: [
                    {
                      id: "xxxx",
                      name: "2B病房"
                      rooms: [
                        {
                          id: "xxxx",
                          name: "一號房",
                          beds: [
                            {
                              id: "xxxx",
                              patientId: ""
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
    */
    getRoomList: (state, getters, rootState) => (filter, params) => {
      params = { ...params } // 將ref轉成一般的Obj 
      filter = { ...filter }

      if (filter.room_id) {
        filter.id = filter.room_id
        delete (filter.room_id)
      }

      let rooms = getters.getFilterData('rooms', filter);

      rooms = rooms.reduce((acc, room) => {
        room.beds = state.beds.reduce((acc, bed) => {

          if (bed.room_id === room.id) {

            bed.status_name = [...rootState.config.statusCodes].find(status => status.code === bed.status)?.name || '';
            bed.reason_name = [...rootState.config.reasonCodes[bed.status] || []].find(reason => {
              return reason.code === bed.status_reason
            })?.name || '';
            if (bed.patient !== undefined) {
              let patient = [...rootState.administration.patients].find(patient => {
                return patient.id === bed.patient
              });
              if (patient) {
                bed.patient_name = patient.familyName + patient.givenName;
              }
            }
            if (filter.bed_id !== undefined && typeof filter.bed_id === 'object') {
              //
            }
            // 如果資料篩選有 bed_id 的話 ...
            if (!filter.bed_id
              || (typeof filter.bed_id === 'string' && bed.id === filter.bed_id)
              || (typeof filter.bed_id === 'object' && ([...filter.bed_id].length === 0 || filter.bed_id.includes(bed.id)))
            ) {
              acc.push({ ...bed })
            }
            // acc.push(bed)
          }
          return acc;
        }, [])

        // 取得建築物名稱 / 樓層 / 病房 資料

        room.building_name = getters.getBuildingNameById(room.building_id);
        room.floor_name = getters.getFloorNameById(room.floor_id);
        room.floor_description = getters.getFloorDescriptionById(room.floor_id);
        room.ward_name = getters.getWardNameById(room.ward_id);

        room.bed_availability =
          room.beds.reduce((count, bed) => {
            return count += (bed.status === 'available') ? 1 : 0;
          }, 0) +
          ' / ' + room.beds.length;

        if (room.beds.length > 0) {
          acc.push({ ...room });
        }

        return acc;
      }, [])
      return rooms

    },

    getBedsById: (state) => roomIds => {
      let result = []
      if (Array.isArray(roomIds)) {

        for (let room_id of roomIds) {
          result = [...result, ...(state.beds.reduce((acc, bed) => {
            if (bed.room_id === room_id) {
              acc.push(bed);
            }
            return acc;
          }, []))]
        }
      }

      return result;
    },

    getRooms: (state, getters) => (params) => {
      let result = getters.getFilterData('');

      return result
    }

  }
}