import store from '@/store'

const chineseNumber = (alaberNumber) => {
    if (typeof alaberNumber === 'number') {
        alaberNumber = String(alaberNumber);
    }
    let chineseNumber = ("零壹貳參肆伍陸柒捌玖").split('');
    let amountSmallUnit = ['', '拾', '佰', '仟'];
    let amountBigUnit = ['', '萬', '億', '兆', '京', '垓', '秭', '穰', '溝', '澗', '正', '載'];
    let alaberNumberSplit = new Array();
    let alaberNumberSplitCount = parseInt(alaberNumber.length / 4);
    for (let i = 0; i <= alaberNumberSplitCount; i++) {
        if (i == 0 && alaberNumber.length % 4 != 0) {
            alaberNumberSplit[i] = alaberNumber.substr(0, alaberNumber.length % 4);
            alaberNumber = alaberNumber.slice(alaberNumber.length % 4);
        } else if (alaberNumber != '') {
            alaberNumberSplit[i] = alaberNumber.substr(0, 4);
            alaberNumber = alaberNumber.slice(4);
        }
    }

    var chineseBigNumber = '';
    for (let i = 0; i < alaberNumberSplit.length; i++) {
        for (let j = 0; j < alaberNumberSplit[i].length; j++) {
            if (alaberNumberSplit[i][0] != 0 && j == 1
                && alaberNumberSplit[i][j] == 0
                && alaberNumberSplit[i].length == 4
                && alaberNumberSplit[i][2] != 0) {
                chineseBigNumber += chineseNumber[alaberNumberSplit[i][j]];
            } else if (alaberNumberSplit[i][j] != 0) {
                chineseBigNumber += chineseNumber[alaberNumberSplit[i][j]];
                chineseBigNumber += amountSmallUnit[alaberNumberSplit[i].length - 1 - j];
            } else if (alaberNumberSplit[i][j] == 0 && alaberNumberSplit[i][j - 1] != 0) {
                if (alaberNumberSplit[i][alaberNumberSplit[i].length - 1] != 0) {
                    chineseBigNumber += chineseNumber[alaberNumberSplit[i][j]];
                }
            }
        }
        if (parseInt(alaberNumberSplit[i]) != 0) {
            chineseBigNumber += amountBigUnit[alaberNumberSplit.length - 1 - i];
        }
    }

    // if (chineseBigNumber != '') {
    //     /* chineseBigNumber += '元整'; */
    //     chineseBigNumber = '新台幣' + chineseBigNumber + '元整';
    // }
    return chineseBigNumber;
}

const getChineseNumber = (number) => {
    if (typeof number === 'string') {
        number = Number(number);
    }

    if (number <= 0 && number >= 100) {
        throw new TypeError('number must between 1 and 99');
    }

    const cString1 = "一,二,三,四,五,六,七,八,九";
    const cAry = cString1.split(",");

    let remainder = number % 10; // 餘數
    let quotient = Math.floor(number / 10); // 商數
    let char1 = remainder > 0 ? cAry[remainder - 1] : "";
    let char2 = quotient > 0 ? "十" : "";
    let char3 = quotient > 1 ? cAry[quotient - 1] : "";

    return `${char3}${char2}${char1}`;
}

const addAlert = (data) => {

    store.commit("notification/addAlert", data);

    return true; // 暫時沒有確認是否有成功
}

// TODO: 驗證啥的都沒做，之後再補！
const addToast = (data) => {
    const cfg = { closeAfter: 5, route: "global" };
    const param = { title: "", message: "" };

    for (let property in cfg) {
        if (data[property] !== undefined) {
            cfg[property] = data[property];
        }
    }
    for (let property in param) {
        if (data[property] !== undefined) {
            param[property] = data[property];
        }
    }
    store.commit("notification/addToast", { ...param, cfg });
}

const chopImage = (image, aspectRatio = 1) => {
    return new Promise((resolve) => {
        // this image will hold our source image data
        const inputImage = new Image();

        // we want to wait for our image to load
        inputImage.onload = () => {
            // let's store the width and height of our image
            const inputWidth = inputImage.naturalWidth;
            const inputHeight = inputImage.naturalHeight;

            // get the aspect ratio of the input image
            const inputImageAspectRatio = inputWidth / inputHeight;

            // if it's bigger than our target aspect ratio
            let outputWidth = inputWidth;
            let outputHeight = inputHeight;
            // 實際的圖比較寬
            if (inputImageAspectRatio > aspectRatio) {
                outputWidth = inputHeight * aspectRatio; // 直接裁掉左右
            } else if (inputImageAspectRatio < aspectRatio) { // 實際圖比較高
                outputHeight = inputWidth / aspectRatio; // 直接裁掉上下
            }

            // calculate the position to draw the image at
            const outputX = (outputWidth - inputWidth) * 0.5;
            const outputY = (outputHeight - inputHeight) * 0.5;

            // create a canvas that will present the output image
            const outputImage = document.createElement('canvas');

            // set it to the same size as the image
            outputImage.width = outputWidth;
            outputImage.height = outputHeight;

            // draw our image at position 0, 0 on the canvas
            const ctx = outputImage.getContext('2d');
            ctx.drawImage(inputImage, outputX, outputY);
            resolve(outputImage);
        };
        // const URL = window.URL || window.webkitURL;
        // start loading our image
        // inputImage.src = image;
        inputImage.src = URL.createObjectURL(image);

    });
}

const fakeApi = (timeout = 3000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, timeout);
    });
};

const wait_a_second = (timeout = 1000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, timeout);
    });
};

const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

export {
    chineseNumber,
    getChineseNumber,
    addAlert,
    addToast,
    chopImage,
    fakeApi,
    genRanHex,
    wait_a_second
}