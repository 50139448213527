import { v4 as uuid } from 'uuid';
import { UbusRequest } from "@/helper/ubus"

export default {
  namespaced: true,
  state() {

    const username = "";
    const ubus_rpc_session = "00000000000000000000000000000000";
    const expires = 300; // 登入有效時間(秒)
    const expires_at = -1; // 登入於何時有效
    const intervalId = -1;
    const session_remain_time = 0;
    const before_login_path = '/';
    const before_login_name = '';
    // 用於記錄登入頁面下的設定值
    const keep_login = false; // 視窗開啟時不自動登出
    const logout_at_close = false; // 頁面關閉時登出
    const remember_login = false; // 記住登入帳號
    const auth_fail_route_name = '';
    const ubus_acls = [];
    const logout_listener_add = false; // 當選取「關閉瀏覽器時登出」登入時會加入 eventListener

    return {
      username, ubus_rpc_session, expires, expires_at,
      intervalId, session_remain_time, before_login_path,
      before_login_name, keep_login, logout_at_close,
      remember_login, auth_fail_route_name, ubus_acls,
      logout_listener_add
    }


  },
  mutations: {
    set_session: function (state, session) {
      if (session === undefined) {
        state.ubus_rpc_session = '00000000000000000000000000000000';
      } else if (typeof (session) === 'string' && session.length === 32) {
        state.ubus_rpc_session = session;
      }
    },
    add_ubus_acl: (state, payload) => {
      const ubus_rpc_session = payload.ubus_rpc_session || state.ubus_rpc_session;
      if (ubus_rpc_session !== '00000000000000000000000000000000' && payload.acls) {
        state.ubus_acls[ubus_rpc_session] = payload.acls;
      }
    },
    del_ubus_acl: (state, ubus_rpc_session) => {
      ubus_rpc_session = ubus_rpc_session || state.ubus_rpc_session;
      if (ubus_rpc_session !== '00000000000000000000000000000000' && payload.acls[ubus_rpc_session]) {
        payload.acls[ubus_rpc_session] = undefined;
      }
    }
  },
  actions: {
    // 登入帳號
    async ubusLogin(context, params) {
      let loginSuccess = false
      let { username, password, logout_at_close, keep_login, remember_login } = params
      let timeout = context.state.expires
      if (keep_login) {
        timeout = 60 * 60 * 24 * 7;
      }
      let argument = { namespace: 'session', procedure: 'login', data: { username, password, timeout } }
      const data = await UbusRequest(argument)
      if (!data.error) {
        let { ubus_rpc_session, expires } = data;
        context.state.session_remain_time = expires;
        let expires_at = new Date().getTime() + (expires * 1000); // js 的 ts 精準到小數後三位
        context.dispatch("saveLogin", { username, ubus_rpc_session, expires_at, logout_at_close, keep_login, remember_login });
        context.state.ubus_rpc_session = ubus_rpc_session;
        context.state.username = username;
        context.state.logout_at_close = logout_at_close;
        context.state.keep_login = keep_login;
        context.state.remember_login = remember_login;

        loginSuccess = true
      }
      return loginSuccess
    },
    // 登出帳號
    async ubusLogout(context) {
      context.state.username = ''
      context.commit('set_session');
      context.state.username = '';
      // if (context.state.logout_listener_add === true) {
      //   window.removeEventListener('beforeunload', context.dispatch('ubusLogout'));
      //   context.state.logout_listener_add = false;
      // }
      localStorage.removeItem('dashboardLoginData') // 刪除登入資料
    },
    async ubusKeepLogin({ state, dispatch }) {
      let argument = { namespace: 'session', procedure: 'list' } // no data needed
      let reqResult = await UbusRequest(argument)

      if (parseInt(reqResult.expires) > 0) {
        const username = reqResult.data.username;
        const expires_at = new Date().getTime() + parseInt(reqResult.expires) * 1000;
        state.expires_at = expires_at;
        dispatch('saveLogin', { username, expires_at })
      }
    },
    // 檢查登入 若 localStorage 有值則寫入相關 state 之中
    // 若 session expires 將狀態恢復到登出狀態 …
    async checkSessionExpire({ state, getters, dispatch }) {

      let page_reopened = false;

      if (!sessionStorage.getItem('reloaded')) {
        page_reopened = true;
      }

      let result = { isLogin: false }
      let current_t = new Date().getTime();

      let storageLoginOption = JSON.parse(localStorage.getItem('dashboardLoginOption'))

      if (storageLoginOption !== null) {
        const { last_loggin_name, keep_login, logout_at_close, remember_login } = storageLoginOption
        state.last_loggin_name = last_loggin_name;
        state.keep_login = keep_login || false;
        state.logout_at_close = logout_at_close || false;
        state.remember_login = remember_login || false;
      }

      let storageLoginData = JSON.parse(localStorage.getItem('dashboardLoginData'))

      if (storageLoginData !== null) {
        let { ubus_rpc_session, username, expires_at } = storageLoginData
        state.username = username;
        // 這邊要加入載入時，是否為首次載入 ..

        if (current_t > expires_at && state.isLoggedIn === true) {
          // 登入expires逾時，執行登出
          dispatch('ubusLogout');
        } else if (page_reopened === true && state.logout_at_close === true) {
          // 頁面關閉，且登入時勾選項目為「關閉瀏覽器時登出
          dispatch('ubusLogout');
        } else {
          if (getters.isLoggedIn === false) {
            state.isLoggedIn = true;
            state.ubus_rpc_session = ubus_rpc_session;
            state.expires_at = expires_at;
          }

          dispatch('setCheckSessionInterval');
          // setInterval

          state.session_remain_time = Math.floor((expires_at - current_t) / 1000)
          result = { isLogin: true, ubus_rpc_session, username }
          if (state.keep_login === true && state.session_remain_time < 240) {
            dispatch('ubusKeepLogin');
          }
        }
      }

      sessionStorage.setItem('reloaded', 'yes');

      return new Promise((resolve) => {
        resolve(result);
      })
    },
    saveLogin(context, params) { // 將資料儲存在 localStorage 之中 ... 
      // 先把資料撈取出來，這樣沒有提供的資料就不會被覆寫
      const loginData = JSON.parse(localStorage.getItem('dashboardLoginData')) || {};
      const loginOption = JSON.parse(localStorage.getItem('dashboardLoginOption')) || {};

      let ubus_rpc_session = params.ubus_rpc_session || context.state.ubus_rpc_session;
      let username = params.username || loginData.username || '';
      let expires_at = params.expires_at || loginData.expires_at || '';

      let { logout_at_close, keep_login, remember_login } = params;
      if (logout_at_close === undefined) { logout_at_close = loginOption.logout_at_close || false }
      if (keep_login === undefined) { keep_login = loginOption.keep_login || false }
      if (remember_login === undefined) { remember_login = loginOption.remember_login || false }
      localStorage.setItem('dashboardLoginData', JSON.stringify({ username, ubus_rpc_session, expires_at }));
      localStorage.setItem('dashboardLoginOption', JSON.stringify({ last_loggin_name: username, logout_at_close, keep_login, remember_login }));
    },
    // 檢查登入是否過期interval - 新增
    setCheckSessionInterval({ state, dispatch }) {
      if (state.intervalId === -1) {
        state.intervalId = setInterval(() => {
          dispatch("checkSessionExpire");
        }, 1000);
      }
    },
    // 檢查登入是否過期interval - 刪除
    clearCheckSessionInterval({ state }) {
      if (state.intervalId !== -1) {
        clearInterval(state.intervalId);
        state.intervalId = -1;
      }
    }
  },
  getters: {
    isLoggedIn: (states) => {
      let result = true;
      result = result && states.ubus_rpc_session !== '00000000000000000000000000000000'
      result = result && states.username !== '';
      return result
    },
    getLoginData(state) {
      return state.username
    },
    getRemainingLoginTimt: state => {
      return state.session_remain_time;
    },
  }
}