import { createApp } from 'vue'
import App from './App.vue'


import i18n from './language'  // 多國語系
import router from './router'
import store from './store'

import './icons';

import 'bootstrap'; //  bootstrap 
import './validators'; // vee-validation rules
// import VeeValidate from 'vee-validate';

const app = createApp(App);

// app.use(VeeValidate);
app.use(router);
app.use(store);
app.use(i18n) // 多國語系

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
app.component('font-awesome-icon', FontAwesomeIcon) //使用font-awsome
app.component('font-awesome-layers', FontAwesomeLayers)
app.component('font-awesome-layers-text', FontAwesomeLayersText)

app.mount('#app');

<script src="scripts/DragDropTouch.js"></script>