// 整個大失敗，完全不會動 沒有任何一個東西是會動的
// 先放棄之後再來好好研究 ... 
// import { nextTick } from 'vue';
// import { useMqtt } from "@/hooks/useMqtt";
import mqtt from "mqtt";
import { genRanHex } from "@/helper/daxin_utils"
// let test = 1;

// const { client } = useMqtt("192.168.2.1", "1883", "gateway", { username: 'api', password: 66483365 });
// let client;

const match_mqtt_topic = (subscribed_topic, received_topic) => {
    let regex_pattern = '^' + subscribed_topic.replace(/\/\+\//, '/([^/]*)/').replace(/#$/, '(.*?)') + '$';
    // regex_pattern = '^' + regex_pattern + '$';
    let res = received_topic.match(regex_pattern);
    if (res && res.length > 0) {
        return res
    }
    return false
}

const ParamBuilder = (cfg) => {
    return {
        clientId: 'daxin_dashboard_' + genRanHex(8),
        keepalive: 300,
        clean: true,
        connectTimeout: 4000,
        // username: cfg.username,
        // password: cfg.password,
        // hostname: cfg.broker,
        username: 'admin', // for testing
        password: 'daxin168',
        hostname: 'daxin.dev',
        // hostname: cfg.server_name,
        protocol: cfg.protocol,
        port: cfg.port,
        debug: false,
    }
}
export default {
    namespaced: true,
    state() {
        const mqtt_cfg = {};
        const _name = '';
        const _connection = {};
        const subscribed_topic = {}; // 
        const callbacks = {} // 把callback放在這邊
        let status;
        return { _connection, callbacks, subscribed_topic, _name, status }
    },
    actions: {
        init: async (context, params) => {
            let { name } = params;
            context.state._name = name;
            let options = ParamBuilder(context.rootState.config.mqtt_server);

            const client = mqtt.connect(options);

            client.on('connect', () => {
                context.state.connected = true
                context.state.status = 'connected';
            });
            client.on('disconnect', () => {
                context.state.status = 'disconnected';
            });
            client.on('close', () => {
                context.state.status = 'disconnected';
            });

            client.on('reconnect', () => {
                context.state.status = 'reconnect';
            });
            client.on('error', (error) => { });
            client.on("message", (topic, msg) => {
                const payload = msg.toString()
                // literate context callbacks to find match cbs
                Object.keys(context.state.callbacks).forEach((subscribed_topic) => {
                    // 回傳符合的條件 若皆不符合回傳 `false`
                    const data = match_mqtt_topic(subscribed_topic, topic);
                    if (data !== false) {
                        const matched_topic = data.shift();
                        const cbAry = context.state.callbacks[matched_topic];
                        if (Array.isArray(cbAry)) {
                            cbAry.forEach(cb => {
                                if (typeof (cb) === 'function') {
                                    cb({ context, topic, payload, data });
                                }
                            })
                        }
                    }
                })

            });
            context.state._connection[context.state._name] = client;
        },

        end: async (context) => {
            if (context.state.status === 'connected') {
                const client = context.state._connection[context.state._name];
                client.end();
            }
        },

        getStatus: (context) => {
        },

        addSubscribe: async ({ state }, params) => {
            let { topic, qos, cb } = params
            if (![0, 1, 2].includes(qos)) {
                qos = 0
            }
            if (state.status === 'connected') {
                const client = state._connection[state._name];
                let res = await client.subscribeAsync(topic, { qos });
                if (res[0]?.topic && typeof (cb) === 'function') {
                    if (state.callbacks[topic] === undefined) {
                        state.callbacks[topic] = [];
                    }
                    state.callbacks[topic].push(cb);
                }
            }
        },

        publish: (context, params) => {

            if (context.state.status === 'connected') {
                const client = context.state._connection[context.state._name];
                if (client) {
                    const { topic, payload } = params
                    client.publish(topic, payload);
                }
            }
        }
    },
    getters: {
        getSubscribes: (state) => {
            return Object.keys(state.callbacks).reduce((res, subscribed_topic) => {
                res[subscribed_topic] = state.callbacks[subscribed_topic].length || 0;
                return res
            }, {})
        }
    }
}