// import dateFormat from "dateformat";

// let getrandomtime = function () {
//   return dateFormat(
//     +new Date() - Math.floor(Math.random() * 360000) + 30000,
//     "yyyy-mm-dd HH:MM:ss"
//   );
// };

import { v4 as uuid } from 'uuid';
import i18n from '@/language'
import router from '@/router';

let get_translate = (item, target) => {
  let result;
  if (item.i18n?.[target] !== undefined) {
    result = i18n.global.t(item.i18n[target], item.i18n.params || {})
  }
  return result || item?.[target] || ''
}

export default {
  namespaced: true,
  state() {
    const toastList = [
      // {
      //   id: '15fd129c-4fbe-4ea5-9321-325511c28854',
      //   title: "設備狀態異動通知...",
      //   message: "設備「DX003」已離線。",
      //   i18n: { params: { device_name: "DX003" }, title: "toast-title-device-status-change", message: "toast-content-device-offline" },
      //   cfg: { closeAfter: , action: "dispose", route: 'Home' },
      //  }
    ];

    const alertList = [
      // {
      //   id: uuid(),
      //   level: "critical",
      //   title: "污桶位移",
      //   message:
      //     "位於「1號房-3號床」設備編號「DX002」發生「污桶位移」告警 請立即進行處理",
      //   i18n: { params: { room_no: 1, bed_no: 3, device_name: "DX002" }, title: "alert-title-device-waste-bucket-misplaced", message: "alert-content-device-waste-bucket-misplaced" },
      //   route: 'Home',
      // },
      // {
      //   id: uuid(),
      //   level: "normal",
      //   title: "排遺數據異常",
      //   message:
      //     "位於「5號房-19號床」設備編號「DX011」發生「生理數據異常」請連絡相關人員進行處理",

      //   i18n: { params: { room_no: 5, bed_no: 19, device_name: "DX011" }, title: "alert-title-device-statistic-abnormal", message: "alert-content-device-statistic-abnormal" },
      //   route: 'Home',
      // },
      // {
      //   level: "critical",
      //   title: "登入失敗",
      //   message:
      //     "帳號或密碼不正確，請重新輸入。",
      //   route: 'Home',
      // },
    ];

    const permission_alert_show = false;
    return { toastList, alertList, permission_alert_show }

  },
  mutations: { // $store.commit('increment',1) // 之後要把新增/修改項目至 state 放這裡 ... 
    addAlert: ((state, { id = uuid(), level = 'critical', title, message, route = 'global', i18n, category }) => {
      // 需要的欄位有 level title message route
      state.alertList.push({ id, level, title, message, route, i18n, category });
    }),
    // id: uuid(),
    // title: "設備編號(DX010)水溫過低...",
    // message: `位於「<font color="red"><strong>7號房-31號床</strong></font>」
    //     設備編號「<font color="red"><strong>DX010</strong></font>」
    //     設定水溫<font color="red">33</font>度目前水溫<font color="red">27.2</font>度，
    //   若持留意水溫過低請連絡相關人員進行了解。`,
    // cfg: { closeAfter: -1, timestamp: new Date() - 7200000, route: 'Home' },
    // addToast: ((state, { id = uuid(), title, content, { closeAfter = -1, timestamp = new Date(), route } }) => {
    addToast: ((state, { id = uuid(), title, message, cfg }) => {
      const { closeAfter = -1, timestamp = new Date(), route = 'global' } = cfg;
      const param = { id, title, message, cfg: { closeAfter, timestamp, route } }
      // 需要的欄位有 level title message route
      state.toastList.push(param);
    }),

    deleteAlartById: ((state, id) => {
      state.alertList = state.alertList.filter(alert => alert.id !== id)
    }),
    deleteAlertByCategory: (state, category) => {
      state.alertList = state.alertList.filter(alert => alert.category !== category)
    },
    deleteToastById: ((state, id) => {
      let tmp = state.toastList.filter(toast => {
        return toast.id !== id
      })
      state.toastList = tmp;
    })
  },
  actions: { // asynchronized request $store.dispatch('addRandomNumber')
    addNotify: ((context, params = {}) => {
      if (window.Notification && Notification.permission === "denied") {
        if (context.getters['permissionDeniedAlertShow'] === false) { // 不重覆顯示權限未啟動通知 
          const params = {
            level: 'critical',
            i18n: {
              title: 'notify-permission-denied-title',
              message: 'notify-permission-denied-message',
            }
          }
          context.commit('addAlert', params);
        }
      } else {

        let { title, body, icon, keepOn: requireInteraction } = params;
        icon = icon || '/img/logo.png';

        console.log('addNotification has been clicked', { title, body, icon, requireInteraction });

        const notification = new Notification(title, { body, icon, requireInteraction });

        notification.onclick = function (event) {
          window.focus();
          router.push("/");
        }

      }
    })
  },
  getters: {
    permissionDeniedAlertShow: (state) => {
      return state.alertList.some(alert => alert.i18n.title === 'notify-permission-denied-title')
    },
    getAlertList: (states) => (route_name) => {
      let temp = states.alertList.filter(item => {
        return item.route === route_name || item.route == 'global'
      }).map(item => {
        item.title = get_translate(item, 'title');
        item.message = get_translate(item, 'message')
        return item;
      });

      return temp;
    },
    getToastList: (states) => {
      return states.toastList.map(item => {
        item.title = get_translate(item, 'title');
        item.message = get_translate(item, 'message')
        return item;
      });
    }

  }
}