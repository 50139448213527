<template>
  <div class="row mb-2">
    <div class="accordion" id="accordionContact">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#deviceLog_' + mac_address">
            {{ t('device.log') }}
          </button>
        </h2>
      </div>
      <div :id="'deviceLog_' + mac_address" class="accordion-collapse collapse show">
        <div class="accordion-body">
          <div class="col text-center">
            <button class="btn btn-primary w-100" @click="getLog()">
              {{ t('get-excretion-log') }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-end">{{ t('data-range') }}:</div>
          <div class="col-8">
            <input class="form-control form-control-sm" v-model="deviceLogRange" type="number" min="1" max="7"
              step="1" />
          </div>
        </div>
        <div v-if="deviceLog.length === 0" class="row">
          <div class="col text-center text-danger">--- {{ t('device.no-excretion-log') }} ---</div>
        </div>
        <template v-else>
          <div class="row mb-1 text-nowrap">
            <!-- <div class="col text-start">序號:</div> -->
            <!-- 智慧尿袋標題列 -->
            <div class="col-4 text-center">{{ t('date') }}</div>
            <div class="col-2 text-center">{{ t('time') }}</div>
            <div class="col-2 text-center">{{ t('duration') }}</div>
            <div class="col-4 text-center ">{{ t('estimate-urine') }}</div>
          </div>
          <template v-for="(record, idx) in deviceLog" :key="idx">
            <div class="row text-nowrap">
              <!-- <div class="col-1 text-start border-bottom">{{ idx + 1 }}</div> -->

              <div class="col-4 text-center border-bottom">
                {{ record.date }}
              </div>
              <div class="col-2 text-center border-bottom">
                {{ record.time }}
              </div>
              <div class="col-2 text-center border-bottom">
                {{ record.duration }} {{ t('second') }}
              </div>
              <div class="col-4 text-center border-bottom">
                {{ record.estimate_urine }} ml
              </div>

            </div>
          </template>


          <div class="row">
            <div class="col text-center my-1">{{ $t('statistic') }}</div>
          </div>
          <div class="row">
          </div>
          <!-- 標題列 -->
          <div class="row text-nowrap">
            <div class="col-3 text-center">{{ $t('date') }}</div>
            <div class="col-3 text-center"> {{ $t('total-duration') }} </div>
            <div class="col-3 text-center"> {{ $t('estimate-urine') }} </div>
            <div class="col-3 text-center"> {{ $t('operation-count') }} </div>
          </div>
          <div v-for="(statistic, date) in log_summary.statistic" :key="date" class="row text-nowrap text-center">
            <div class="col-3">{{ date }}</div>
            <div class="col-3">{{ statistic.total_duration }}</div>
            <div class="col-3">{{ statistic.estimate_urine }}</div>
            <div class="col-3">{{ statistic.operation_count }}</div>
          </div>

        </template>
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { subDays, getUnixTime, fromUnixTime, startOfDay, format, toDate } from "date-fns";
import { UbusRequest } from "@/helper/ubus";

export default defineComponent({
  name: "DeviceLog",
  props: {
    mac_address: {
      required: true,
      type: String,
    }
  },
  emits: ["requestFunction"],

  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const store = useStore();
    const deviceLogRange = ref(3);
    const deviceLog = ref([]);

    const query_date_start_t = computed(() => {
      return getUnixTime(startOfDay(subDays(new Date(), deviceLogRange.value - 1)))
    });

    /*
  {{ record.date }}
  {{ record.time }}
  {{ t(record.type_code) }}
  {{ record.weight }}
*/

    const log_summary = ref({});

    const getLog = async () => {
      console.log('props.device_type:', props.device_type);
      const params = { namespace: 'device_new', procedure: 'get_urine_bag_log', data: { start_t: query_date_start_t.value, mac: props.mac_address } }
      console.log('params:', params);
      console.log(fromUnixTime(query_date_start_t.value), format(fromUnixTime(query_date_start_t.value), 'yyyy-MM-dd HH:mm'))
      deviceLog.value = [];
      let res = await UbusRequest(params)
      console.log('res:', res);
      // 2024/06/12 加入統計
      log_summary.value = {
        start_t: format(fromUnixTime(query_date_start_t.value), 'yyyy-MM-dd HH:mm'),
        end_t: format(new Date(), 'yyyy-MM-dd HH:mm'),
        statistic: {}
        // stool: { count: 0, weight: 0 },
        // urine: { count: 0, weight: 0 }
      }

      if (!res.error && Array.isArray(res.list)) {
        for await (const record of res.list) {
          const event_time = toDate(record.event_time);
          const date = format(event_time, 'yyyy-MM-dd');
          const time = format(event_time, 'HH:mm');
          // 統計數據
          if (log_summary.value.statistic[date] === undefined) {
            log_summary.value.statistic[date] = { total_duration: 0, estimate_urine: 0, operation_count: 0 };
          }

          log_summary.value.statistic[date]['total_duration'] += Number(record.duration);
          log_summary.value.statistic[date]['estimate_urine'] += Number(record.estimate_urine);
          log_summary.value.statistic[date]['operation_count']++;

          deviceLog.value.push({
            date,
            time,
            duration: record.duration,
            estimate_urine: record.estimate_urine,
          });
        }
      }

    };
    return { t, deviceLog, deviceLogRange, getLog, query_date_start_t, log_summary };
  },
});
</script>

<style></style>