<template>
    <generic-modal modal-id="comfirm-modal" @onModalMounted="modalMounted" :btn-center="true" :size="modal.size">
        <template #modal-header>
            <div class="row text-danger">
                <h4>
                    {{ modal.header }}
                </h4>
            </div>
        </template>
        <template #modal-body>
            <div class="row">
                <div class="col text-center">
                    <!-- {{ modal.body }} -->
                    <span v-html="modal.body"></span>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <button @click="modal_callback(true)" class="btn btn-primary">{{ modal.confirm_context }}</button>
            <button @click="modal_callback(false)" class="btn btn-danger">{{ modal.cancel_context }}</button>
        </template>
    </generic-modal>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import GenericModal from '@/components/modal/GenericModal';

const store = useStore();

const modal_show_computed = computed(() => {
    return store.state.ui.modal_show;
})

watch(modal_show_computed, (newVal, oldVal) => {
    if (newVal === true) {
        modalInstance.show();
    } else {
        modalInstance.hide();
    }
})

let modalInstance;

const modalMounted = (i) => {
    modalInstance = i;
}

const modal = computed(() => {
    return store.getters['ui/getModal']
})

const modal_callback = (result) => {
    store.commit('ui/set_modal_result', result);
}

// const test = async () => {
//     console.log('test has been called');
//     console.log(store.state.ui.comfirm_callback());
//     let result = await store.dispatch('ui/confirm', {
//         header: '確認刪除', body:
//             `確認刪除代碼<font color="red">test-text</font>？`,
//         size: 'sm', auto_close: 10
//     });
//     console.log('result:', result);
// }

</script>

<style></style>