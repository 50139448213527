export default {
  namespaced: true,
  state() { // 存放資料用 ... 
    const relationship = {
      system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
      codes: [
        {
          code: "FTH",
          text: "father",
          description: "父",
        },
        {
          code: "MTH",
          text: "mother",
          description: "母",
        },
        {
          code: "SIB",
          text: "sibling",
          description: "手足",
        },
        {
          code: "BRO",
          text: "brother",
          description: "兄弟",
        },
        {
          code: "SIS",
          text: "sister",
          description: "姐妹",
        },
        {
          code: "WIFE",
          text: "wife",
          description: "妻",
        },
        {
          code: "HUSB",
          text: "husband",
          description: "夫",
        },
        {
          code: "GCHILD",
          text: "grandchild",
          description: "孫子(女)",
        },
        {
          code: "GPARNT",
          text: "grandparent",
          description: "祖父(母)",
        },
        {
          code: "FRND",
          text: "unrelated friend",
          description: "非親屬關係朋友",
        },
        {
          code: "FM",
          text: "Family Member",
          description: "其他親屬關係",
        },
      ]
    }

    const vitalSignsCodes = {

      "LoincCode": {
        "system": "http://loinc.org",
        "codes": {
          // https://www.hl7.org/fhir/observation-example-f202-temperature.json.html
          "3137-7": {
            "display": "Body height Measured",
            "description": "身高",
            "unit": "cm",
            "category": "Vital Signs",
            "scenarios": [1]
          },
          "29463-7": {
            "display": "Body Weight",
            "description": "體重",
            "unit": "kg",
            "category": "Vital Signs",
            "scenarios": [1]
          },
          "8310-5": {
            "display": "Body Temperature",
            "description": "體溫",
            "unit": "Cel",
            "category": "Vital Signs",
            "scenarios": [1, 5]
          },
          "87422-2": {
            "display": "Blood Glucose Post Meal",
            "description": "進食後血糖",
            "unit": "mg/dL",
            "category": "Laboratory Data",
            "scenarios": [1]
          },
          "88365-2": {
            "display": "Blood Glucose Pre Meal",
            "description": "進食前血糖",
            "unit": "mg/dL",
            "category": "Laboratory Data",
            "scenarios": [1]
          },
          "41982-0": {
            "display": "Percentage of body fat Measured",
            "description": "體脂率",
            "unit": "%",
            "category": "Vital Signs",
            "scenarios": [1]
          },
          "83174-3": {
            "display": "Grip strength Hand - right Dynamometer",
            "description": "握力",
            "unit": "kg",
            "category": "Vital Signs",
            "scenarios": [1]
          },
          "59408-5": {
            "display": "Oxygen saturation in Arterial blood by Pulse oximetry",
            "description": "SPO2血氧飽和濃度",
            "unit": "%",
            "category": "Vital Signs",
            "scenarios": [1, 5]
          },
          "35094-2": {
            "display": "Blood Pressure Panel",
            "description": "血壓",
            "unit": "mmHg",
            "category": "Vital Signs",
            "scenarios": [2, 5]
          },
          "8480-6": {
            "display": "Systolic Blood Pressure",
            "description": "收縮壓",
            "unit": "mmHg",
            "category": "Vital Signs",
            "scenarios": [2, 5]
          },
          "8462-4": {
            "display": "Distolic Blood Pressure",
            "description": "舒張壓",
            "unit": "mmHg",
            "category": "Vital Signs",
            "scenarios": [2, 5]
          },
          "8867-4": {
            "display": "Heart Rate",
            "description": "心率",
            "unit": "{beats}/min",
            "category": "Vital Signs",
            "scenarios": [2]
          },
          "38263-0": {
            "display": "DXA Femur [T-score] Bone density",
            "description": "股骨骨密度",
            "unit": "{T-score}",
            "category": "Image",
            "scenarios": [3]
          },
          // https://build.fhir.org/ig/HL7/US-Core/Observation-dxa-hip-l-t-score-bmd.json.html
          "80948-3": {
            "display": "DXA Femur - left [T-score] Bone density",
            "description": "左股骨骨密度",
            "unit": "{T-score}",
            "category": "Image",
            "scenarios": [3]
          },
          "80947-5": {
            "display": "DXA Femur - right [T-score] Bone density ",
            "description": "右股骨骨密度",
            "unit": "{T-score}",
            "category": "Image",
            "scenarios": [3]
          },
          "38267-1": {
            "display": "DXA Lumbar spine [T-score] Bone density",
            "description": "腰椎骨密度",
            "unit": "{T-score}",
            "category": "Image",
            "scenarios": [3]
          },

          "8889-8": {
            "display": "Heart rate by Pulse oximeter",
            "description": "心率",
            "unit": "{beats}/min",
            "category": "Vital Signs",
            "scenarios": [5]
          },
          "9279-1": {
            "display": "Respiratory Rate",
            "description": "呼吸頻率",
            "unit": ["{breaths}/min", "{counts}/min"],
            "category": "Vital Signs",
            "scenarios": [5]
          },
          "44963-7": {
            "display": "Capillary refill [Time] of Nail bed	Exam",
            "description": "微血管充填時間",
            "unit": "s",
            "category": "Vital Signs",
            "scenarios": [5]
          },
          "2339-0": {
            "display": "Glucose [Mass/volume] in Blood	Laboratory	LoincCode",
            "description": "血糖",
            "unit": "mg/dL",
            "category": "Laboratory",
            "scenarios": [5]
          },

        }
      },
      "IEEE11073": { //urn:iso:std:iso:11073:10101
        "system": "urn:oid:2.16.840.1.113883.6.24",
        "code": {
          "131328": {  // Scenarios 4-12-Lead ECG https://simplifier.net/Test20171049/Observation-example-42/~json
            "display": "MDC_ECG_ELEC_POTL",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },

          // 上面是心電圖的主 code  下面使用 component 傳 ... 上面的範例不是用 http://lonic.org 
          // 下面的資料格式要再確認一下 ... 
          //   {
          //     "code": {
          //         "coding":  [
          //             {
          //                 "system": "urn:oid:2.16.840.1.113883.6.24",
          //                 "code": "131330",
          //                 "display": "MDC_ECG_ELEC_POTL_II"
          //             }
          //         ]
          //     },
          //     "valueSampledData": {
          //         "origin": {
          //             "value": 2048
          //         },
          //         "period": 10,
          //         "factor": 1.612,
          //         "lowerLimit": -3300,
          //         "upperLimit": 3300,
          //         "dimensions": 1,
          //         "data": "2041 2043 2037 2047 2060 2062 2051 2023 2014 2027 2034 2033 2040 2047 2047 2053 2058 2064 2059 2063 2061 2052 2053 2038 1966 1885 1884 2009 2129 2166 2137 2102 2086 2077 2067 2067 2060 2059 2062 2062 2060 2057 2045 2047 2057 2054 2042 2029 2027 2018 2007 1995 2001 2012 2024 2039 2068 2092 2111 2125 2131 2148 2137 2138 2128 2128 2115 2099 2097 2096 2101 2101 2091 2073 2076 2077 2084 2081 2088 2092 2070 2069 2074 2077 2075 2068 2064 2060 2062 2074 2075 2074 2075 2063 2058 2058 2064 2064 2070 2074 2067 2060 2062 2063 2061 2059 2048 2052 2049 2048 2051 2059 2059 2066 2077 2073"
          //     }
          // },
          "131329": { // 
            "display": "MDC_ECG_ELEC_POTL_I",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131330": {
            "display": "MDC_ECG_ELEC_POTL_II",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131389": {
            "display": "MDC_ECG_ELEC_POTL_III",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131390": {
            "display": "MDC_ECG_ELEC_POTL_AVR",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131391": {
            "display": "MDC_ECG_ELEC_POTL_AVL",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131392": {
            "display": "MDC_ECG_ELEC_POTL_AVF",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131331": {
            "display": "MDC_ECG_ELEC_POTL_V1",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131332": {
            "display": "MDC_ECG_ELEC_POTL_V2",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131333": {
            "display": "MDC_ECG_ELEC_POTL_V3",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131334": {
            "display": "MDC_ECG_ELEC_POTL_V4",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131335": {
            "display": "MDC_ECG_ELEC_POTL_V5",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          },
          "131336": {
            "display": "MDC_ECG_ELEC_POTL_V6",
            "description": "",
            "unit": "",
            "category": "Procedure",
            "scenarios": [4]
          }, // Scenarios 5-Vital Signs in EMS
        }
      },
    }

    // const category = {
    //   "system": "http://hl7.org/fhir/observation-category",
    // }

    const measures = {
      "system": "http://unitsofmeasure.org",
      codes: {
        "Cel": "degrees C",
        "{T-Score}": "T-Score",
        "mg/dL": "mg/dL",
        "s": "second",
        "{breaths}/min": "{breaths}/min",
        "{counts}/min": "{counts}/min"
      }
    }

    const extension = {
      "address":
      {
        "tw-section": { // "三段"
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
        "tw-number": { // "210號"
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
        "tw-village": { // "大有里" 村/里
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
        "tw-neighborhood": { // "19鄰"
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
        "tw-lane": { // "52巷" 巷/衖
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
        "tw-alley": { // "6弄"
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
        "tw-floor": { // "2樓"
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
        "tw-room": { // "B室"
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-section",
          "type": "valueString"
        },
      },

      "_postalCode": {
        "tw-postal-code": {
          "url": "https://twcore.mohw.gov.tw/fhir/StructureDefinition/tw-postal-code",
          "type": "valueCodeableConcept",
          "code": {
            "system": "https://twcore.mohw.gov.tw/fhir/CodeSystem/postal-code3-tw",
            "type": "code"
          }
        }
      }
    }

    const boilerplate = {
      "Patient": {
        "resourceType": "Patient",
        "meta": {
          "profile": [
            "https://twcore.mohw.gov.tw/fhir/StructureDefinition/Patient-twcore",
          ],
        },
        "identifier": [],
        "name": [],
        "gender": "",
        "address": [],
        "telecom": [],
        "contact": [
          {
            "relationship": [],
            "name": {},
            "telecom": [],
          },
        ],
      },
      "identifier": {
        "use": "official",
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/v2-0203",
              "code": "", // NI 身份證字號 MR 病歷號
            },
          ],
        },
        "system": "", // 身份證 http://www.moi.gov.tw/ 病歷號 https://www.tph.mohw.gov.tw/
        "value": "",
      },
      "_postalCode": {
        "extension": [],
      },
      "name": {
        "use": "official",
        "text": "",
        "family": "",
        "given": [""],
      },
      "telecom": {
        "system": "",
        "value": "",
        "use": "",
        "period": {
          "start": "",
        },
      },
      "relationship": {
        "coding": [
          {
            "system": "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
            "code": "",
          },
        ],
      },
      "address": {
        "country": "TW", // Taiwan 
        "city": "", // 縣/市
        "district": "",  // 鄉/鎮/市/區
        "line": [], // 路/街 -> 不知為何是陣列
        "extension": [], // 參照上面的 extension
      }

    }

    return {
      relationship, measures, extension
    }
  },
  mutations: { // $store.commit('increment',1)

  },
  actions: { // asynchronized request $store.dispatch('addRandomNumber')

  },
  getters: {

  }

}
