<template>
  <div>
    <bs-alert
      v-for="alert in alertList"
      :key="alert.id"
      :level="alert.level"
      :title="alert.title"
      :message="alert.message"
      :id="alert.id"
      @close-alert="closeAlert"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import BsAlert from "@/components/bootstrap/BsAlert.vue";
export default defineComponent({
  name: "AlertBlock",
  components: { BsAlert },
  setup() {
    const route = useRoute();
    const store = useStore();
    const alertList = computed(() =>
      store.getters["notification/getAlertList"](route.name)
    );

    const closeAlert = (id) => {
      store.commit("notification/deleteAlartById", id);
    };

    return { alertList, closeAlert };
  },
});
</script>

<style>
</style>