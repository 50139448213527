<template>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarCanvas" aria-labelledby="offcanvasExampleLabel"
    style="z-index: 10000">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">
        <!-- {{ sidebarTitle }} -->
        {{ $t('menu-list') }}
      </h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body mt-3">
      <div>
        <nav class="nav nav-pills flex-column">
          <!-- <router-link to="/" class="nav-link active">資訊看版</router-link>
          <router-link to="/location" class="nav-link">病房管理</router-link> -->
          <router-link v-for="(item, idx) in menu" :to="item.route" :key="idx" class="nav-link"
            :class="{ active: item.active }">
            <!-- {{ item.text }} -->
            {{ $t(item.translateCode) }}
          </router-link>
          <!-- <a class="nav-link disabled" href="#">病房管理</a> -->
          <!-- <a class="nav-link disabled" href="#">病人管理</a> -->
          <!-- <a class="nav-link disabled">設備管理</a> -->
          <!-- <a class="nav-link disabled" href="#">照服員資料管理</a> -->
          <!-- <router-link to="/test" class="nav-link">{{ $t( 'test-page' )}}</router-link> -->
        </nav>
      </div>
      <!-- <div class="dropdown mt-3">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
        >
          {{ $t('logout')}}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li><a class="dropdown-item" href="/">{{ $t('login')}}</a></li>
          <li><a class="dropdown-item" href="/login">{{ $t('logout')}}</a></li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { reactive, watchEffect, defineComponent } from "vue";
export default defineComponent({
  name: "SideBar",
  props: { title: { type: String, required: false } },

  setup(props) {
    const menu = reactive([
      { route: "/", text: "資訊看版", active: false, translateCode: 'dashboard' },
      { route: "/log", text: "排遺記錄查詢", active: false, translateCode: 'log-query-page' },
      { route: "/config", text: "資訊平台設定頁", active: false, translateCode: 'dashboard-config-page' },
    ]);

    if (window.location.hostname === 'localhost') {
      menu.push({ route: "/location", text: "病房管理", active: false, translateCode: 'location-management' });
      menu.push({ route: "/patient", text: "住民管理", active: false, translateCode: 'patient-management' });
      menu.push({ route: "/device", text: "設備管理", active: false, translateCode: 'device-management' });
      menu.push({ route: "/practitioner", text: "從業人員管理", active: false, translateCode: 'practitioner-management' });
      menu.push({ route: "/mqtt", text: "設備資訊頁面", active: false, translateCode: 'device-mqtt-page' });
    }

    if (window.location.hostname === 'daxin.dev') {
      menu.push({ route: "/location", text: "病房管理", active: false, translateCode: 'location-management' });
    }
    const route = useRoute();
    watchEffect(() => {
      for (let item of menu) {
        item.active = item.route === route.path;
      }
    });
    const sidebarTitle = props.title || "功能選單";
    return { sidebarTitle, menu };
  },
});
</script>

<style></style>