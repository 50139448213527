
import { UbusRequest } from "@/helper/ubus"

export default {
  namespaced: true,
  state: function () {

    const ubus = [];
    const primary_key = {};
    const current_datetime = new Date();
    const load_progress = 0;
    const maxBuildingHeight = 20;
    const roomAdd = { skipFour: true, autoNaming: true };
    const bedAdd = { skipFour: true, autoNaming: true };
    const floorAdd = { skipFour: true, autoNaming: true, defaultName: '一般病房' };
    const deviceType = ['smart-toilet'];
    const deviceGenericProperty = {
      id: 'string',
      manufacturer: 'string',
      manufactureDate: 'timestamp',
      expirationDate: 'timestamp',
      status: 'enum',
      location: 'uuid'
    }; // 設備通用屬性

    const tagCategory = [
      { code: 'physical', name: '生理狀況' },
      { code: 'medical_compliance', name: '醫療指示' },
      { code: 'care_guidance', name: '照護指南' },
      { code: 'preference', name: '個人喜好' },
    ]
    const patientTag = {
      physical: ['發燒', '失溫', '臥床'],
      medical_compliance: ['禁食', '管灌', '點滴', '術後恢復'],
      care_guidance: ['防跌', '注意保暖', '約束'],
      preference: ['素食'],
    };
    const language = ['國語', '英文', '台語', '客家話', '原住民語'];

    const reasonCodes = {
      unavailable: [
        { code: 'not-acceptance', name: '尚未驗收' },
        { code: 'not-installed', name: '尚未安裝' },
        { code: 'maintainace', name: '設備維護' },
        { code: 'repair', name: '設備維修' },
        { code: 'malfunction', name: '設備異常' },
        { code: 'retired', name: '設備除疫' },
        { code: 'unknown', name: '狀態不明' },
        { code: 'sterilization', name: '滅菌' },
        { code: 'disinfection', name: '清消' },
        { code: 'cleaning', name: '設備清潔' },
      ],
      retired: [
        { code: 'acceptance-failed', name: '驗收失敗' },
        { code: 'service-life', name: '已逾使用年限' },
        { code: 'not-repairable', name: '無法修復' }
      ],
      occupied: [
        { code: 'in-use', name: '現正使用中' },
        { code: 'reserved', name: '預約保留中' },
      ],
    }
    const statusCodes = [
      { code: '', name: '請選擇', disabled: true },
      { code: 'registered', name: '已註冊' },
      { code: 'assigned', name: '已分派' },
      { code: 'occupied', name: '已被使用' },
      { code: 'available', name: '可使用' },
      { code: 'unavailable', name: '不可使用' },
      { code: 'retired', name: '已除疫' },
    ]

    const alertCodes = {
      'smart-toilet': [
        { code: 'waste-bucket-misplaced', name: '污桶位移' },
        { code: 'waste-bucket-full', name: '污桶已滿' },
        { code: 'water-shortage', name: '水箱缺水' },
        { code: 'hardware-error', name: '空燒警告' },
        { code: 'water-overheated', name: '水溫過高' },
      ]
    }

    // 用於設備屬性的形態，如水溫 -> Float .

    const dataTypes = [
      { code: 'Float', name: '浮點數', description: '帶小數點的數字如3.14等' },
      { code: 'Integer', name: '整數', description: '不帶小數點的數字如1,100,1000等' },
      { code: 'Boolean', name: '布林值', description: '真(true)或假(false)' },
      // 這二個很難結構化，先不實作 ... 
      // { code: 'Array', name: '陣列', description: '陣列' }, 
      // { code: 'Object', name: '物件', description: '物件' }, 
      { code: 'String', name: '字串', description: '字串' },
      { code: 'Datetime', name: '日期', description: '可轉成日期的字串，如2022-08-02 12:34:56' },
    ]

    // 通用型的屬性單位，例如 (次、)

    const serverList = [
      { id: '', name: 'gateway', ip: '192.168.6.1', port: 9001, services: ['mqtt'] }
    ]

    // mqtt 相關設定值放這裡 ...
    const mqtt_server = {
      server_id: 2,
      broker: 'daxin.zapto.org',
      port: 1884,
      protocol: 'wss',
      auth: 'password',
      username: 'dashboard',
      password: '66483365',
      server_name: 'npm server(localhost)'
    }

    const api_server = {
      host: (window.location.hostname === 'localhost') && 'daxin.dev' || window.location.hostname,
      protocol: 'https',
      prefix: '/ubus'
    }

    // 2024/4/16 新增全域變數

    const institute = {
      name: '臺北市立行愛住宿長照機構',
      address: '台北市內湖區行善路250號',
      contact: {
        email: 'service@abc.com',
        phone: ' 02-2796-3886'
      }
    }

    const building_names = ['大成樓', '學成樓', '明倫樓'];

    // const landing_page_msssage = "大心生物關心您。";
    // 通用性資料，無權限
    const dashboard = [];
    // const dashboard = { generic: [] };

    // { generic: [
    //      { property: "splash_screen_message", value: "大心生物關心您。" },
    //      { property: "logo", value: "logo.png" },
    //      { property: "debug", value: "false" },
    //    ]
    // }

    const storage = {}


    return {
      floorAdd, maxBuildingHeight, roomAdd, bedAdd, deviceType, deviceGenericProperty, patientTag, language,
      reasonCodes, statusCodes, tagCategory, dataTypes, alertCodes, mqtt_server, institute,
      building_names, api_server,
      load_progress, dashboard,
      // 
      ubus, // for ubus api use
      test: [],
      storage,
      current_datetime, // 目前系統時間 ... 
    }
  },
  mutations: {
    load_progress_add: (state) => {
      if (state.load_progress < 100) {
        state.load_progress++;
      } else {
        state.load_progress = 0;
      }
    },
    updateCurrentDatetime: (state) => {
      state.current_datetime = new Date();
    }
  },
  actions: {
    // 這個是用來取得單一設定檔的，例如 mqtt_server
    // 這邊的 state 是物件方式存取，並且一定要先設定
    // mqtt_server = { ... } 
    getOneConfigFromUbus: async function (context, target) {
      console.log('target:', target);
      const available_configs = ['mqtt_server'];
      if (available_configs.includes(target)) {
        let res = await UbusRequest({ namespace: 'config', procedure: target });
        if (!res.list) {
          console.log('ubus get fail', target)
        } else {
          Object.keys(res.list[0]).forEach(key => {
            context.state[target][key] = res.list[0][key];
          })
        }
      }
    },
    // 這個是通用型的設定檔取得方式... 沒有權限問題的資料放這邊 ... 
    getConfigFromUbus: async function (context, target) {
      // const available_configs = ['dashboard'];
      // const anonymous_procedures = ['dashboard']
      let anonymous = true;
      // if (available_configs.includes(target)) {
      // if (anonymous_procedures.includes(target)) {
      //   anonymous = true;
      // }
      let res = await UbusRequest({ namespace: 'config', procedure: 'get', anonymous, data: { category_name: target } })
      if (res.list && typeof (res.list) === 'object') {
        context.state.storage[target] = []; // 清空
        for await (const row of res.list) {
          context.state.storage[target].push({ property: row.property, value: row.value, id: row.id });
        }
      } else if (res.rows_count === 0) { // 沒有符合的資料
        context.state.storage[target] = []; // 清空
      }

      // 這邊不這樣寫就在 畫面中就會直接執行下去，不會等待api完成 .
      return new Promise((resolve, reject) => {
        resolve(true);
      })
    },
    loadStatusCodesFromUbus: async function (context) {
      const argument = { namespace: 'config', procedure: 'status_codes' }
      let res = await UbusRequest(argument)
      if (res.error === false) {
        // console.log('res.list', res.list);
        context.state.statusCodes = res.list;
        return context.state.statusCodes
      }

    },
    loadReasonCodesFromUbus: async function (context, status_code) {
      const argument = { namespace: 'config', procedure: 'reason_codes', data: { status_code } }
      let res = await UbusRequest(argument)
      if (res.error === false) {

        // if (!status_code) {

        //   context.state.reasonCodes = res.list.reduce((codesAry, row) => {

        //     if (codesAry[row.status_code] === undefined) {
        //       codesAry[row.status_code] = []
        //     }
        //     codesAry[row.status_code].push(row);

        //     return codesAry;
        //   }, {});
        //   return context.state.reasonCodes;
        // } else {
        context.state.reasonCodes = res.list;
        return res.list
        // }
      }
    },
    delDateFromUbus: async function (context, { target, del_list }) {
      const del_res = { success: [], fail: [] }
      if (Array.isArray(del_list)) {
        // 迴圈刪除項目 ... 
        for await (const id_to_be_deleted of del_list) {
          let params = {
            namespace: 'config',
            procedure: 'get',
            data: {
              id: id_to_be_deleted,
              method: "delete"
            }
          }
          const res = await UbusRequest(params);
          if (res.rows_count && res.error === false) {
            del_res.success.push(id_to_be_deleted);
          } else {
            del_res.fail.push(id_to_be_deleted);
          }
        }
        return new Promise((resolve, reject) => {
          resolve(del_res);
        })
      }
    },
    // 這邊只會有 `update` ，不會有刪除/新增 ... 
    // 每個設定檔都有自已的 db 所以要先建立，之後只會針對個別項目進行 修改
    // mqtt_server ... 
    setOneDataToUbus: async function (context, payload) {
      let result = false;

      let { target: procedure, method, data } = payload
      const namespace = 'config';
      method = method || 'update'; // 目前只有 update 

      const { fields, primary_key } = await context.dispatch('get_params', { namespace, procedure });
      if (!fields || !primary_key) {
        throw new Error('could not get ubus params');
      }
      if (procedure === 'mqtt_server') {
        if (method === 'update') {
          const pk_val = data['server_id'];
          delete data[primary_key];
          let dataToSend = {
            data: {
              [primary_key]: pk_val,
              update_values: data,
              method
            },
            namespace,
            procedure
          }
          let res = await UbusRequest(dataToSend);
          result = res.rows_count > 0
        }
      }

      return new Promise((resolve) => {
        resolve(result);
      })
    },
    // 將資料設定到 ubus 之中 ... 
    setDataToUbus: async function (context, payload) {
      const namespace = 'config'; // 所有在 config 這個 store 的 namespace 都是 store …
      let { target, method, data } = payload
      const procedure = 'get';
      const errors = [];
      const result = {
        update: {
          success: [],
          fail: [],
          fail_count: 0
        },
        insert: {
          success: [],
          fail: [],
          fail_count: 0
        }
      };
      const { fields, primary_key } = await context.dispatch('get_params', { namespace, procedure });
      if (!fields || !primary_key) {
        errors.push('could not get ubus params');
      } else {
        method = method || 'insert';
        /*
        資料格式：
        {
            "dashboard": [
                {
                    "property": "header_logo",
                    "value": "logo.png",
                    "id": 2
                },
                {
                    "property": "debug",
                    "value": "true",
                    "id": 3
                },
                {
                    "property": "splash_screen_message",
                    "value": "大心生物關心您。",
                    "id": 5
                }
            ]
        }
        */
        for await (let category of Object.keys(data)) {
          for await (const new_state of data[category]) {
            let ubus_params;
            let method;
            let pk_val;
            if (new_state[primary_key]) {
              // 用id找到原先的 state ..
              let old_state = await context.state.storage[category].find(state_cfg => state_cfg[primary_key] === new_state[primary_key])
              // 用key逐項確認內容是否有變更
              const state_changed = Object.keys(old_state).some(key => old_state[key] !== new_state[key]);
              // 如果內容有變更，則使用UbusRequest修改資料 ...
              if (state_changed) {
                method = 'update';
                pk_val = new_state[primary_key];
                delete (new_state[primary_key]);
                ubus_params = {
                  namespace,
                  procedure: 'get',
                  data: {
                    method,
                    [primary_key]: pk_val,
                    update_values: new_state,
                  }
                }
              }
            } else {
              method = 'insert'
              ubus_params = {
                namespace: 'config',
                procedure: 'get',
                data: {
                  method,
                  ...new_state,
                  category_name: category
                }
              }
            }
            // 有參數才需要處理，沒有參數可能是資料沒有變更 ... 
            if (ubus_params) {
              let res = await UbusRequest(ubus_params);
              pk_val = pk_val || res.last_insert_id;
              if (res.rows_count > 0 && pk_val) {
                result[method].success.push(pk_val)
              } else {
                if (pk_val) {
                  result[method].fail.push(pk_val)
                }
                result[method].fail_count++;
              }
              if (res.error.message) {
                errors.push(res.error.message);
              }
            }
          }
        }
      }
      return new Promise((resolve) => {
        resolve(result);
      })
    },

    get_params: async ({ state, getters }, params) => {
      const { force_update, namespace, procedure } = params;
      if (force_update === true || getters['getParams']({ namespace, procedure }) === undefined) {
        const res = await UbusRequest({ namespace: 'gateway_dashboard_api', procedure: 'get_params', data: { namespace, procedure } })
        if (res.result !== 'error') {
          if (res.fields) {
            state.ubus.push(res);
          }
        }
      }
      return new Promise((resolve, reject) => {
        resolve(getters['getParams']({ namespace, procedure }));
      })
    },
    initialize: async ({ state, dispatch }) => {
      if (window.location.hostname !== 'localhost') {
        await dispatch('getOneConfigFromUbus', 'mqtt');
      }
      return new Promise((resolve) => {
        resolve(true);
      });
      // 如果是 localhost 代表目前是 npm 執行的 debug 

    },
    testAction: async function (context) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ a: 'rocky' })
        }, 1000)
      })

    }
  },
  getters: {
    get: state => property => {
      return state[property];
    },
    getTags: state => category => {
      return state.patientTag[category] || []
    },
    getReasons: state => category => {
      if (state.reasonCodes[category]) {
        return state.reasonCodes[category]
      }
      return []
    },
    getReasonCategories: state => {
      return Object.keys(state.reasonCodes);
    },
    getMqtt: state => state.mqtt_server,
    getBuildings: state => state.building_names.slice(),
    getMaxinumBuidingHight: state => state.maxBuildingHeight,
    getParams: state => data => {
      const { namespace, procedure } = data;
      let params = state.ubus.find(item => item.procedure === procedure && item.namespace === namespace)
      return params
    },
    getConfig: (state) => (config_name) => {
      if (state.storage[config_name] === undefined) {
        state.storage[config_name] = [];
      }
      return state.storage[config_name]
    },
    getValue: state => (category, property) => {
      let value = '';
      if (state.storage[category] && Array.isArray(state.storage[category])) {
        state.storage[category].forEach(cfg => {
          if (cfg.property === property) {
            value = cfg.value
          }
        })
      }
      return value
    }
  }
}