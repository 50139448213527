<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    const progres_percentage = computed(() => store.state.config.load_progress);

    const msg = computed(() => {
      const splash_screen_message = store.getters['config/getValue']('dashboard', 'splash_screen_message') || '';
      return splash_screen_message.split("")
    })

    return { progres_percentage, msg }
  }
};
</script>
<template>
  <div class="fullscreen">
    <div class="d-flex container loader">
      <!-- <span><img src="@/assets/xtmu-w-bg.png" height="80 px" class="" /></span>
      <span>✖</span> -->
      <span v-if="msg.length > 0"><img src="@/assets/logo.png" height="60 px" class="logo" /></span>
      <span v-for="(chr, idx) in msg" :key="idx">{{ chr }}</span>
      <!-- <span>({{ progres_percentage }})</span> -->
    </div>
    <div class="sticky">
      <div class="row">
        <div class="col text-center text-light">資料載入中({{ progres_percentage }}%)...</div>
      </div>
      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="{ width: progres_percentage + '%' }" :aria-valuenow="progres_percentage" aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.logo {
  border: 1px white solid;
  border-radius: 50%;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
  background-color: #771326;
  margin: 0;
  padding: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.loader span {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px;
  color: aliceblue;
  display: inline-block;
  transition: all 0.5s;
  animation: animate 2s infinite;
}

.loader span:nth-child(1) {
  animation-delay: 0.1s;
}

.loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader span:nth-child(3) {
  animation-delay: 0.3s;
}

.loader span:nth-child(4) {
  animation-delay: 0.4s;
}

.loader span:nth-child(5) {
  animation-delay: 0.5s;
}

.loader span:nth-child(6) {
  animation-delay: 0.6s;
}

.loader span:nth-child(7) {
  animation-delay: 0.7s;
}

@keyframes animate {
  0% {
    color: aliceblue;
    transform: translateY(0);
    margin-left: 0;
  }

  25% {
    color: aliceblue;
    transform: translateY(-15px);
    margin-left: 10px;
    text-shadow: 0 15px 5px rgba(0, 0, 0, 1);
  }

  100% {
    color: aliceblue;
    transform: translateY(0);
    margin-left: 0;
  }

}

.sticky {
  position: absolute;
  bottom: 0;
  width: 100vw;
}
</style>